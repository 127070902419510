import React, { useEffect, useRef, useState, useCallback } from "react";
import { AsyncSelect } from "@atlaskit/select";
import apiClient from "../../api/api-client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CreateDocumentModal from "../documents/create-document-modal";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import Select from "@atlaskit/select";
import useDocument from "../../hooks/use-document";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";



function SearchDocumentsByType({ typeId, onChange, value, isDisabled, isModal, filter = null, link = null, selectedDocumentId, selectedDocumentTypeId, document, autoFocus = true, direction = -1, isClearable = true, isRequired = false, filterByRootDocument = false, root = null }) {
    const [open, setOpen] = useState(false);
    const [allOptions, setAllOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const selectRef = useRef();
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(null);
    const { isSuccess, document: doc, fields, isPending } = useDocument(selectedDocumentId, selectedDocumentTypeId);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isSuccess) return;
        if (!selectedDocumentId) {
            setSelected(null);
            return;
        }

        setSelected({
            value: doc?.id,
            label: findLabel(doc, fields),
            data: doc,
        });
    }, [typeId, selectedDocumentId, isSuccess, doc?.id]);

    // const filterMethod = React.useCallback(item => {
    //     if (!filter || !link) return true;
    //     const itemLinks = item?.data?.links?.filter(il => il?.document_type?.id === link?.filtered_by_id)?.map(il => il?.id) || [];
    //     return itemLinks?.includes(filter?.value);
    // }, [ filter, link, filter?.value ]);

    // const filterByDocumentMethod = React.useCallback(item => {
    //     if (!link?.filterByDocument) return true;
    //     const linkedDocumentIds = document?.links?.map(i => i?.linked_document_id);
    //     return linkedDocumentIds?.includes(item?.value);
    // }, [ link?.filterByDocument, document?.id ]);

    useEffect(() => {
        // console.log(selectRef?.current);
        // todo, clear async select
    }, [typeId]);

    useEffect(() => {
        // if (!query) return;
        debouncedChangeHandler(query);
    }, [filter?.value, query, typeId]);

    // useEffect(() => {
    //     setOptions(allOptions.filter(i => {
    //         if (filterMethod && filterByDocumentMethod) {
    //             return filterByDocumentMethod(i) && filterMethod(i);
    //         }
    //         if (filterMethod) return filterMethod(i);
    //         if (filterByDocumentMethod) return filterByDocumentMethod(i);
    //         return true;
    //     }));
    // }, [ filter, link ]);

    useEffect(() => {
        search(query);
    }, [typeId]);

    const search = q => {
        setIsLoading(true);
        let params = {};
        let url = `workflow/document-type/${typeId}/documents?query=${q}&per_page=50&dir=${direction}`;

        if (filter && link && filter?.value) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: filter?.value }
                ],
            };
        }

        if (link?.filterByDocument) {
            params = {
                condition: "AND",
                links: [
                    { operator: "=", document_id: document?.id }
                ],
            };
        }

        if (link?.additionalSearchField) {
            url = `workflow/document-type/${typeId}/documents?per_page=50&dir=${direction}`;
            params = {
                condition: "OR",
                filters: [
                    {
                        name: "name",
                        operator: "contains",
                        value: q,
                    },
                    {
                        name: link.additionalSearchField,
                        operator: "contains",
                        value: q,
                    },
                ]
            };
        }

        if (root != null && filterByRootDocument) {
            params = {
                ...params,
                condition: "AND",
                links: [
                    { operator: "=", document_id: root?.id }
                ],
            };
        }

        apiClient(url, { method: "POST", data: params })
            .then(response => {
                const opt = response?.data?.map(i => {
                    const label = i.values?.find(a => a.name == "name")?.value;
                    const additionalField = link?.additionalSearchField ? i.values?.find(a => a.name == link?.additionalSearchField)?.value : null;

                    return ({
                        label: additionalField ? `${additionalField} - ${label}` : label,
                        value: i.id,
                        data: i,
                    });
                });

                setAllOptions([...opt]);
                setOptions([...opt]);

                // setOptions(opt.filter(i => {
                //     if (filterMethod && filterByDocumentMethod) {
                //         return filterByDocumentMethod(i) && filterMethod(i);
                //     }
                //     if (filterMethod) return filterMethod(i);
                //     if (filterByDocumentMethod) return filterByDocumentMethod(i);
                //     return true;
                // }));

                return Promise.resolve(opt);
            })
            .catch(() => {
                setOptions([]);
                Promise.resolve([]);
            })
            .finally(() => setIsLoading(false));
    };


    const debouncedChangeHandler = useCallback(debounce(search, 500), [filter?.value, typeId]);

    return <Wrapper>
        <SelectWrapper>
            <Select autoFocus={autoFocus}
                // loadOptions={search} 
                value={selected}
                options={options}
                onChange={onChange}
                inputValue={query}
                onInputChange={setQuery}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isRequired={isRequired}
                isClearable={isClearable}
                filterOption={() => true}
                // defaultOptions={options} 
                ref={selectRef} />
        </SelectWrapper>
        {isModal && <Row>
            <AddNewWrapper>
                <Link to="" onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}>
                    <Button appearance="default">
                        {t("search_documents_by_type_add")}
                    </Button>
                </Link>
            </AddNewWrapper>
            <Spacer />
        </Row>}
        {open && <CreateDocumentModal documentTypeId={typeId} open={open} onClose={() => setOpen(false)} onSubmit={e => {
            setOpen(false);
            onChange({
                label: e.name,
                value: e.id
            });
        }} />}
    </Wrapper>;
}

function findLabel(document, fields) {
    const field = fields?.find(i => i?.name === "name");

    if (!field) return "";

    return document?.values?.[field?.id] ?? "";
}


export default SearchDocumentsByType;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const AddNewWrapper = styled.div`
    padding: 10px 5px;
    font-weight: bold;
    padding-left: 20px;
`;