import DynamicTable from "@atlaskit/dynamic-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { Link } from "react-router-dom";
import spacePath from "../../utils/space-path";
import Button from "@atlaskit/button";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EmptyState from "@atlaskit/empty-state";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import DocumentTableNewRecord from "./document-table-new-record";
import useDocumentFields from "../../hooks/use-document-fields";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import DocumentTableEditRecord from "./document-table-edit-record";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";

export default function DocumentTable({ name, document, xId, columns = [], editable = true, document_links = [], onAdded, onRemoved, onEdited, onLinkRemoved, onUpdated, summary = false }) {
    const { t } = useTranslation();
    const links = document?.links
        ?.filter(i => i?.linked_document?.document_type_id == xId)
        ?.map(i => i?.linked_document);
    const [ isPending, setIsPending ] = useState(true);
    const [ data, setData ] = useState(null);
    const [ addFormOpen, setAddFormOpen ] = useState(false);
    const [ recordToEdit, setRecordToEdit ] = useState(null);
    const [ recordToRemove, setRecordToRemove ] = useState(null);
    const [ addedData, setAddedData ] = useState([]);
    const [ editedData, setEditedData ] = useState({});
    const { fields } = useDocumentFields(xId);
    const [ linksCache, setLinksCache ] = useState({});
    const quickFormFields = fields?.filter(i => toBoolean(i?.quick_form)) ?? [];
    const visibleColumns = columns?.filter(i => !toBoolean(i?.hidden));

    useEffect(() => {
        if (editable) return;

        setAddedData([]);
    }, [ editable ]);
    
    useEffect(() => {
        if (!links) return;

        setIsPending(true);

        Promise.all(links?.map(link => {
            if (linksCache[link?.id]) {
                return Promise.resolve(linksCache[link?.id]);
            }

            return apiClient(`workflow/document/${link?.id}`).catch(() => {});
        }))
            .then(data => {
                setData(data);
                setLinksCache(data?.reduce((acc, item) => {
                    acc[item?.id] = item;
                    return acc;
                }, {}));
            })
            .catch(() => setData(null))
            .finally(() => setIsPending(false));

        return () => {
            setData(null);
        };
    }, [ xId, document?.id, editable, links?.length ]);

    const head = {
        cells: [
            { key:"main_name", content: "", width: 100, shouldTruncate: true },
            ...visibleColumns.map(c => {
                const content = c.label;
                return {
                    key: c.name, 
                    content: <Align align={c?.align || "left"}>{content}</Align>, 
                    width: c?.type == "value" ? 50 : 100, 
                    shouldTruncate: true, 
                    isSortable: false,
                    
                };
            }),
            { key: "actions", content: null, width: 50, shouldTruncate: false,  }
        ]
    };

    const rows = data?.map(d => {
        return {
            key: d?.id,
            cells: [
                {
                    key: "main_name",
                    content: <span>{findName(d, fields, editedData)}</span>
                },
                ...visibleColumns.map(c => {
                    const content = c?.type === "value" ? findValue(d, c, editedData) : findRelation(d, c);

                    return {
                        key: c.name, content: <Align align={c?.align || "left"}>{content || "-"}</Align>,
                    };
                }),
                { key: "actions", content: editable && <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => {
                            const values = d?.values?.reduce((acc, item) => {
                                const columnName = columns?.find(i => i?.document_type_field_id == item?.document_type_field_id)?.name;
                                if (!columnName) return acc;
                                acc[columnName] = item?.value;
                                if (editedData[d?.id] && editedData[d?.id][columnName]) {
                                    acc[columnName] = editedData[d?.id][columnName];
                                }
                                return acc;
                            }, {});

                            values.id = d?.id;

                            setRecordToEdit(values);
                        }}>
                            {t("document_table_edit")}
                        </DropdownItem>
                        <DropdownItem onClick={() => {
                            setData(data => data?.filter(i => i?.id !== d?.id));
                            // const linkId = document?.links?.find(i => i?.linked_document?.id === d?.id)?.id;
                            onRemoved && onRemoved(d?.id);
                        }}>
                            {t("document_table_remove")}
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>}
            ],
        };
    }) || [];

    let summaryRows = [];

    if (summary && (data?.length + addedData?.length) > 0) {
        summaryRows.push({
            key: "summary",
            cells: [
                { key: "main_name", content: "", },
                ...columns.map(c => {
                    if (!c?.summary) {
                        return { key: c.name, content: <SummaryCell /> };
                    }

                    let sum = 0;

                    data?.map(d => {
                        const value = c?.type === "value" ? findValue(d, c, editedData) : 0;
                        sum += Number(value);
                    });
                    
                    addedData?.map(d => {
                        const value = d[c?.name] || 0;
                        sum += Number(value);
                    });

                    if (c?.fixed) {
                        sum = Number(sum).toFixed(parseInt(c?.fixed));
                    }

                    return { key: c.name, content: <Align align={c?.align || "left"}>
                        <SummaryCell>{sum}</SummaryCell>
                    </Align> };
                }),
                { key: "actions", content: null },
            ]
        });
    }

    const additionalRows = addedData?.map(d => {
        return {
            key: d?.id,
            cells: [ 
                { key: "main_name", content: "", },
                ...columns?.map(c => {
                    const key = c?.name;
                    let value = c?.type === "value" ? d[key] : findAddedRelation(d[key]?.data, c);

                    if (c?.type === "value" && value && c?.fixed) {
                        value = Number(value).toFixed(parseInt(c?.fixed));
                    }

                    const content = value || "-";

                    return {
                        key: c.name, content: <Align align={c?.align || "left"}>{content || "-"}</Align>,
                    };
                }),
                { key: "actions", content: editable && <DropdownMenu position="bottom right" triggerType="button">
                    <DropdownItemGroup>
                        <DropdownItem onClick={() => setRecordToEdit(d)}>
                            {t("document_table_edit")}
                        </DropdownItem>
                        <DropdownItem onClick={() => {
                            onLinkRemoved && onLinkRemoved(d?.id);
                            setAddedData(data => data?.filter(i => i?.id !== d?.id));
                        }}>
                            {t("document_table_remove")}
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>}
            ]
        };
    }) || [];

    const allRows =[
        ...rows,
        ...additionalRows,
        ...summaryRows,
    ];

    return <>
        <Wrapper>
            <Heading>
                <h4></h4>
                { editable && <Button iconBefore={<EditorAddIcon />} onClick={() => setAddFormOpen(true)}></Button>}
            </Heading>
            <DynamicTable
                head={head} 
                rows={allRows}
                highlightedRowIndex={summary ? allRows.length - 1 : undefined}
                isFixedSize
                loadingSpinnerSize="large"
                isLoading={isPending}
                emptyView={<div>{t("document_table_empty")}</div>}
            />
            {data?.length === 0 && editable && <EmptyState header="" description="" primaryAction={<Button appearance="primary" onClick={() => setAddFormOpen(true)}>{t("document_table_add")}</Button>} />}
            {editable && addFormOpen && <DocumentTableNewRecord 
                open={addFormOpen} 
                onSubmit={formData => {
                    const item = {
                        ...formData,
                        id: uuidv4(),
                        added: true,
                    };

                    setAddedData(d => ([ ...d, item]));

                    setAddFormOpen(false);
                    onAdded && onAdded(item);
                }}
                editable={editable}
                fields={quickFormFields}
                columns={columns}
                document_links={document_links}
                document={document}
                onClose={() => setAddFormOpen(false)} />}
            {editable && recordToEdit && <DocumentTableEditRecord
                open={true} 
                onSubmit={formData => {
                    if (recordToEdit?.added) {
                        onEdited && onEdited(recordToEdit, formData);
                        setAddedData(data => data?.map(d => {
                            if (d?.id === recordToEdit?.id) {
                                return {
                                    ...d,
                                    ...formData,
                                };
                            }
                            return d;
                        }));
                    } else {
                        onUpdated && onUpdated(recordToEdit, formData);
                        setEditedData(data => ({
                            ...data,
                            [recordToEdit?.id]: formData
                        }));
                    }

                    setRecordToEdit(null);
                }}
                editable={editable}
                fields={quickFormFields}
                record={recordToEdit}
                columns={columns}
                document_links={document_links}
                onClose={() => setRecordToEdit(null)} />}
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
    margin-top: 20px;
    padding: 15px 25px 20px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
    font-size: 14px;
`;

const Heading = styled.div`
    display: flex;

    &>h4 {
        flex-grow: 1;
        margin-bottom: 10px;
    }
`;

function findName(data, fields, editedData = {}) {
    const field = fields?.find(i => i?.name === "name");

    if (!field) return null;

    let value = data?.values?.find(i => i?.document_type_field_id == field?.id)?.value || null;

    return <Link to={spacePath(data?.document_type?.space) + "/" + data?.id}>
        <RelationWrapper>
            <RelationIcon>
                <Page16Icon />
            </RelationIcon>
            <div>
                {value || null}
            </div>
        </RelationWrapper>
    </Link>;
}

function findValue(data, column, editedData = {}) {
    let value = data?.values?.find(i => i?.document_type_field_id == column?.document_type_field_id)?.value || null;

    if (editedData[data?.id]) {
        const fieldName = data?.document_type?.fields?.find(i => i?.id == column?.document_type_field_id)?.name;
        if (fieldName && editedData[data?.id][fieldName]) {
            value = editedData[data?.id][fieldName];
        }
    }

    if (column?.fixed && value) {
        value = Number(value).toFixed(parseInt(column?.fixed));
    }

    return value;
}

function findRelation(data, column) {
    const document = data?.links?.find(i => i?.linked_document?.document_type_id == column?.document_type_id);
    if (!document) return null;
    const value = document?.linked_document?.values?.find(i => i?.document_type_field_id == column?.document_type_field_id);
    const documentId = document?.linked_document?.id;
    const space = document?.linked_document?.document_type?.space;
    return <Link to={spacePath(space) + "/" + documentId}>
        <RelationWrapper>
            <RelationIcon>
                <Page16Icon />
            </RelationIcon>
            <div>
                {value?.value || null}
            </div>
        </RelationWrapper>
    </Link>;
}

function findAddedRelation(document, column) {
    if (!document) return null;
    const value = document?.values?.find(i => i?.name == column?.document_type_field_name);
    const documentId = document?.id;
    const { module, space, category } = document;
    const moduleSlug = space?.category?.module?.name ?? space?.module?.name ?? category?.module?.name ?? space?.module ?? module ?? "";
    const path = `/${moduleSlug}/${category.slug}/${space?.slug}`.toLowerCase();
    return <Link to={path + "/" + documentId}>
        <RelationWrapper>
            <RelationIcon>
                <Page16Icon />
            </RelationIcon>
            <div>
                {value?.value || null}
            </div>
        </RelationWrapper>
    </Link>;
}

const RelationWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
`;

const RelationIcon = styled.div`
    margin-right: 5px;
    margin-top: 2px;
`;

const Align = styled.div`
    text-align: ${p => p?.align};
`;

const SummaryCell = styled.div`
    font-weight: 600;
    padding: 7px 0px;
    font-size: 13px;
`;