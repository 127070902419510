import { useEffect, useState, useCallback } from "react";
import useAsync from "../hooks/use-async";
import apiClient from "../api/api-client";
import { toast } from "react-toastify";

export default function useDocumentWorkflows(document) {
    const [ workflows, setWorkflows ] = useState([]);
    const { run } = useAsync();

    const callback = useCallback(workflow => {
        return run(apiClient(`workflow/action/${workflow.id}`, { data: { documentActionId: workflow.id }}))
            .then(response => {
                switch (response?.action) {
                case "message":
                    toast[response?.data?.type](response?.data?.message);
                    break;
                default:
                    return Promise.resolve(response);
                }
            })
            .catch(error => {
                toast.error("Nie udało się uruchomić workflow: " + error?.message);
            });
    }, [ document.id ]);

    useEffect(() => {
        if (!document) return;
        
        setWorkflows(document.actions.map(action => ({
            label: action.name,
            id: action.id,
            workflow_id: action?.workflow?.id,
        })));
    }, [document]);

    return {
        data: workflows,
        callback,
    };
}