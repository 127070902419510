import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/nav/navbar";
import { useAuth } from "../providers/auth-provider";
import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";

export default function DashboardLayout({ children }) {
    const { user, logout, reload, userStatus, globalStatus, setGlobalStatus, setUserStatus } = useAuth();

    useEffect(() => {
        if (!user) return;
        const channelName = `user-status.${user?.id}`;
        Echo.channel(channelName)
            .listen(".Updated", (e) => {
                setUserStatus(e);
            })
            .listen(".Removed", (e) => {
                setUserStatus(undefined);
            });

        
        return () => Echo.leaveChannel(channelName);
    }, [ user ]);

    useEffect(() => {
        if (!user) return;
        const channelName = "status";
        Echo.channel(channelName)
            .listen(".Updated", (e) => {
                setGlobalStatus(e);
            })
            .listen(".Removed", (e) => {
                setGlobalStatus(undefined);
            });

        
        return () => Echo.leaveChannel(channelName);
    }, [ user ]);
    
    return <Wrapper>
        <Navbar user={user} logout={logout} />
        <ContentWrapper addPadding={!!globalStatus || !!userStatus}>
            {globalStatus && <GlobalStatus status={globalStatus} />}
            {userStatus && !globalStatus && <GlobalStatus status={userStatus} />}
            <Content padding="30px" addPadding={!globalStatus && !userStatus}>
                {children}
            </Content>
        </ContentWrapper>
    </Wrapper >;
}

const Wrapper = styled.div`
`;


const ContentWrapper = styled.div`
padding-top: ${props => props.addPadding ? "56px" : "0px"};
min-height: 100vh;
`;

const Content = styled.div`
    padding-left: ${props => props.padding};
    padding-top: calc(${props => props.addPadding ? "56px" : "0px"} + ${props => props.padding} - 10px);
    padding-right: ${props => props.padding};
    padding-bottom: ${props => props.padding};
    background: white;
    z-index: 201;
`;

function GlobalStatus({ status: { content, type }}) {
    return <GlobalStatusWrapper>
        <SectionMessage appearance={type}>
            <p>{content}</p>
        </SectionMessage>
    </GlobalStatusWrapper>;
}

const GlobalStatusWrapper = styled.div`
width: 100%;
`;