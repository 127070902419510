const domain = window.location.hostname;
let API_URL = process.env.API_URL ?? "https://business.staging.vseli.com"; //todo

if (domain == "app.vseli.com") {
    API_URL = "https://business.vseli.com";
}

if (domain == "staging.vseli.com") {
    API_URL = "https://business.staging.vseli.com";
}

export {
    API_URL
};

async function client(endpoint, { data, method, headers: customHeaders, blob = false, ...customConfig } = {}) {
    const token = window.localStorage.getItem("auth.token");
    const config = {
        method: method ? method : (data ? "POST" : "GET"),
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            "Accept": "application/json",
            "Authorization": token ? `Bearer ${JSON.parse(token)}` : undefined,
            "Content-Type": data ? "application/json" : undefined,
            ...customHeaders,
        },
        ...customConfig,
    };

    return window.fetch(`${API_URL}/${endpoint}`, config)
        .then(async response => {
            let data;

            try {
                data = blob ? await response.blob() : await response.json();
            } catch (e) {
                data = null;
            }
            
            if (response.status === 401) {
                window.localStorage.removeItem("auth.token");
                window.location.href = "/login?redirect=" + window.location.pathname;
                return Promise.reject(data);
            }

            if (response.ok) {
                return data;
            } else {
                return Promise.reject({
                    ...data,
                    status: response.status,
                });
            }
        })
        .catch(e => {
            return Promise.reject(e || { message: "Unexpected error" });
        });
}

export default client;