import React from "react";
import Form, { Field, FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import PageHeader from "@atlaskit/page-header";
import useLogin from "./use-login";
import { Link } from "react-router-dom";
import Select from "@atlaskit/select";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function LoginForm({ redirect }) {
    const { login, isError, error, isPending } = useLogin();
    const { t } = useTranslation();
    const [ locale, setLocale ] = React.useState(i18next.language?.slice(0, 2) ?? "en");

    const languageOptions = [
        { label: "English", value: "en" },
        { label: "Polski", value: "pl" },
    ];

    const submit = data => {
        login({
            ...data,
            locale
        }).then(() => {
            window.location.href = redirect || "/";
        }).catch(() => { });
    };

    return <Wrapper>
        <PageHeader>{t("login_header")}</PageHeader>
        <Form onSubmit={data => submit(data)}>
            {({ formProps }) => (
                <form {...formProps}>
                    <Field name="email" defaultValue="" label={t("login_email")} isRequired>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="email" isInvalid={isError && error?.errors?.email} />}
                    </Field>
                    <Field name="password" defaultValue="" label={t("login_password")} isRequired>
                        {({ fieldProps }) => <TextField type="password" {...fieldProps} isInvalid={isError && error?.errors?.password} testId="password" />}
                    </Field>
                    <Field name="locale" label={t("login_language")} isRequired>
                        {({ fieldProps }) => <Select 
                            name="locale"
                            options={languageOptions} 
                            value={languageOptions.find(i => i.value == locale)}
                            defaultValue={locale}
                            onChange={e => {
                                setLocale(e.value);
                                i18next.changeLanguage(e.value);
                            }}
                        />}
                    </Field>
                    <FormFooter>
                        <FormFooterWrapper>
                            <Link to="/reset">
                                {t("login_reset_password")}
                            </Link>
                            <Spacer />
                            <ButtonGroup>
                                <Button type="submit" appearance="primary" isLoading={isPending}>
                                    {t("login_button")}
                                </Button>
                            </ButtonGroup>
                        </FormFooterWrapper>
                    </FormFooter>
                </form>
            )}
        </Form>

        <SectionMessageWrapper>
            {isError
                ? <SectionMessage title={t("login_error_header")} appearance="error">
                    <p>{error?.message || "Unexpected error"}</p>
                </SectionMessage>
                : <SectionMessage title={t("login_no_account_header")}>
                    <p>{t("login_no_account_message")}</p>
                </SectionMessage>}
        </SectionMessageWrapper>
    </Wrapper>;
}

export default LoginForm;

const Wrapper = styled.div`
    width: 400px;
    padding: 0 20px;
`;

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const FormFooterWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;