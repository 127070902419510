import { useEffect, useState, useCallback } from "react";
import apiClient from "../api/api-client";
import documentsListResponse from "../api/documents-list-response";
import documentFieldsResponse from "../api/document-fields-response";
import useAsync from "../hooks/use-async";
import { getMonth, getQuarter, getYear } from "date-fns";

export default function useDocumentsPivotList(documentTypeId, fieldNames = [], options = {
    numericFieldTypeId: 2, 
    dateFieldTypeId: 4, 
    selectFieldTypeId: 3, 
}) {
    const { numericFieldTypeId, dateFieldTypeId, selectFieldTypeId } = options;
    const fields = useAsync();
    const list = useAsync();

    useEffect(() => {
        if (!documentTypeId) return;
        fields.run(apiClient(`document-types/${documentTypeId}`)).catch(() => { });
    }, [documentTypeId]);

    useEffect(() => {
        if (!documentTypeId) return;
        if (fields?.isPending || fields?.isIdle) return;
        list.run(
            apiClient(`workflow/document-type/${documentTypeId}/documents?paginate=false`, { method: "POST" })
                .then(response => {
                    return Promise.all(
                        response?.data?.map(document => {
                            const documentValuesMap = document?.values?.reduce((acc, i) => {
                                if (!fieldNames?.includes(i?.name)) return acc;
                                
                                const field = fields?.data?.find(f => f?.name === i?.name);
                                let value = i.value;

                                if (field?.field_id === numericFieldTypeId) {
                                    value = Number(value)?.toFixed(2)?.replace(".", ",");
                                }

                                if (field?.field_id === dateFieldTypeId) {
                                    try {
                                        const date = Date.parse(value);
                                        const year = getYear(date);
                                        const month = getMonth(date) + 1;
                                        const quarter = getQuarter(date);
                                        acc[`${i.label} (Rok)`] = year;
                                        acc[`${i.label}  (Miesiąc)`] = month;
                                        acc[`${i.label}  (Kwartał)`] = `Q${quarter} ${year}`;
                                    } catch (e) {
                                        acc[`${i.label}  (Rok)`] = null;
                                        acc[`${i.label}  (Miesiąc)`] = null;
                                        acc[`${i.label}  (Kwartał)`] = null;
                                    }
                                }

                                if (field?.field_id === selectFieldTypeId) {
                                    const currentOption = field?.options?.find(opt => opt?.value === value);

                                    if (currentOption?.name) {
                                        value = currentOption?.name;
                                    }

                                    field?.options?.map(o => {
                                        const name = document?.values?.find(dv => dv?.name === "name")?.value;
                                        if (name) {
                                            acc[`${i.label}: ${field?.label} - ${o?.name}`] = name;
                                        }
                                    });
                                }

                                acc[i.label] = value;
                                return acc;
                            }, {});

                            return apiClient(`workflow/document/${document?.id}`)
                                .then(documentResponse => {
                                    const linkedDocuments = documentResponse?.links?.map(i => i?.linked_document) || [];
                                    const documentValues = linkedDocuments?.reduce((acc, linked) => {
                                        const linkedValues = linked?.values;
                                        const documentTypeLabel = linked?.document_type?.name;

                                        linkedValues?.map(v => {
                                            const documentTypeName = linked?.document_type?.name;
                                            if (!fieldNames?.includes(`${documentTypeName}:${v?.document_type_field?.name}`)) return;

                                            const fieldLabel = v?.document_type_field?.label;
                                            let value = v?.value;

                                            if (v?.document_type_field?.field_id === numericFieldTypeId) {
                                                acc[`${documentTypeLabel}: ${fieldLabel}`] = Number(value)?.toFixed(2)?.replace(".", ",");
                                                return;
                                            }

                                            if (v?.document_type_field?.field_id === dateFieldTypeId) {
                                                try {
                                                    const date = Date.parse(value);
                                                    const year = getYear(date);
                                                    const month = getMonth(date) + 1;
                                                    const quarter = getQuarter(date);
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Rok)`] = year;
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Miesiąc)`] = month;
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Kwartał)`] = `Q${quarter} ${year}`;
                                                } catch (e) {
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Rok)`] = null;
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Miesiąc)`] = null;
                                                    acc[`${documentTypeLabel}: ${fieldLabel} (Kwartał)`] = null;
                                                }
                                            }

                                            if (v?.document_type_field?.field_id === selectFieldTypeId) {
                                                const currentOption = v?.document_type_field?.options?.find(opt => opt?.value === value);

                                                if (currentOption?.name) {
                                                    value = currentOption?.name;
                                                }

                                                v?.document_type_field?.options?.map(o => {
                                                    const name = linked?.values?.find(i => i?.document_type_field?.name === "name")?.value;
                                                    if (name) {
                                                        acc[`${documentTypeLabel}: ${fieldLabel} - ${o?.name}`] = name;
                                                    }
                                                });

                                                // return;
                                            }

                                            acc[`${documentTypeLabel}: ${fieldLabel}`] = value;
                                        });
                                        return acc;
                                    }, documentValuesMap);
                                    return documentValues;
                                });
                        })
                    ).then(data => {
                        return data;
                    });
                })
        ).catch(() => { });
    }, [documentTypeId, fields?.isPending, fields?.isIdle]);

    const keys = new Set();
    const data = [ ...list?.data ?? [] ];

    list?.data?.map(item => {
        for (const key in item) {
            keys.add(key);
        }
    });

    return {
        documents: data?.map(d => {
            for (const key of keys) {
                if (!(key in d) || !d[key]) {
                    d[key] = "-";
                }
            }
            return d;
        }) ?? null,
        fields: fields.data ? documentFieldsResponse(fields.data) : [],
        isPending: list.isIdle || list.isPending || fields.isIdle || fields.isPending,
        isError: list.isError || fields.isError,
        isSuccess: list.isSuccess && fields.isSuccess,
    };
}