/* eslint-disable no-case-declarations */
import React from "react";
import { Field } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import Select from "@atlaskit/select";
import { Checkbox } from "@atlaskit/checkbox";
import { DatePicker } from "@atlaskit/datetime-picker";
import TextField from "@atlaskit/textfield";
import styled from "styled-components";
import FormTable from "./form-table";
import { Editor, WithEditorActions, EditorContext } from "@atlaskit/editor-core";
import { JIRATransformer } from "@atlaskit/editor-jira-transformer";
import MultiSelect from "@atlaskit/multi-select";
import DocumentTable from "../documents/document-table";
import SearchDocumentsByType from "../documents/search-documents-by-type";

function FormFieldUncontrolled({ field, inputProps, document, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave, propagateFields, setDocumentsToUpdateAfterSave }) {
    if (field.field.type === "select") {
        inputProps.defaultValue = field.options
            .map(i => ({ label: i.name, value: i.value }))
            .find(i => i.value == inputProps.defaultValue);
    }
    
    if (field.field.type === "multiselect") {
        const selectedValues = inputProps?.rawValues?.filter(i => i?.document_type_field_id === field?.id)?.map(i => i?.value) || [];
        inputProps.defaultSelected = field.options
            .map(i => ({ content: i.name, value: i.value }))
            .filter(i => selectedValues?.includes(i?.value));
    }

    if (field.field.type === "section") {
        return <Section>{field.label}</Section>;
    }

    if (field.field.type === "table") {
        return <FormTable {...inputProps} label={field.label} table_column={field?.table_column} />;
    }

    const rawOptions = field?.options?.[0];
    let optionsData = {};
    try {
        optionsData = JSON.parse(rawOptions);
    } catch (e) {
        //
    }

    const propagateField = (data) => {
        const valuesMap = data?.values?.reduce((acc, i) => {
            acc[i?.name] = i?.value;
            return acc;
        }, {});

        propagateFields(field, valuesMap);
    };

    return <Wrapper>
        <Field {...inputProps}>
            {({ fieldProps }) => { 
                switch (field.field.type) {
                case "text":
                    return <TextField {...fieldProps} />;
                case "number":
                    return <TextField type="number" {...fieldProps} step="any" />;
                case "textarea":
                    return <TextArea {...fieldProps} />;
                case "select":
                    return <Select
                        {...fieldProps}
                        options={field.options.map(i => ({ label: i.name, value: i.value }))}
                    />;
                case "multiselect":
                    const selectedValues = inputProps?.rawValues?.filter(i => i?.document_type_field_id === field?.id)?.map(i => i?.value) || [];
                    return <MultiSelect 
                        {...fieldProps}
                        shouldFitContainer
                        defaultSelected={inputProps?.defaultSelected || []}
                        items={field.options?.sort((a, b) => {
                            const aIndex = selectedValues?.findIndex(i => i === a?.value);
                            const bIndex = selectedValues?.findIndex(i => i === b?.value);
                            
                            return aIndex > bIndex;
                        })?.map(i => ({
                            content: i?.name, 
                            value: i?.value,
                        }))} />;
                case "date":
                    return <DatePicker {...fieldProps} locale="pl-PL"  />;
                case "checkbox":
                    return <Checkbox {...fieldProps} 
                        defaultChecked={inputProps.defaultValue} 
                        defaultValue={true} 
                        label={field?.options?.label} />;
                case "wysywig":
                    return <EditorContext>
                        <WithEditorActions
                            render={actions => (
                                <Editor 
                                    {...fieldProps}
                                    defaultValue={inputProps.defaultValue}
                                    allowExpand
                                    allowConfluenceInlineComment
                                    autoScrollIntoView
                                    // mentionProvider={mentionProvider}
                                    contentTransformerProvider={schema =>
                                        new CustomJiraTransformer(schema, 
                                            // { mention: mentionEncoder }
                                        )
                                    }
                                    // mentionInsertDisplayName={false}
                                    appearance="comment" />
                            )} />
                    </EditorContext>;
                case "document-type":
                    if (!rawOptions) return "-";
                    if (!optionsData?.documentTypeId) return "-";
                    return <SearchDocumentsByType 
                        onChange={e => {
                            propagateField(e?.data);
                        }}
                        typeId={optionsData?.documentTypeId} {...inputProps} />;
                case "list":
                    if (!document) return "-";
                    if (!rawOptions) return "-";

                    return <DocumentTable 
                        document={document} 
                        xId={optionsData?.document_type_id} 
                        name={optionsData?.label}
                        summary={optionsData?.summary}
                        onLinkRemoved={linkId => {
                            setDocumentsToLinkAfterSave(data => data?.filter(d => {
                                if (d?.data?.id === linkId) return false;
                                return true;
                            }));
                        }}
                        onRemoved={linkId => {
                            setDocumentsToUnlinkAfterSave(d => ([ ...d, linkId ]));
                        }}
                        onEdited={(recordToEdit, formData) => {
                            setDocumentsToLinkAfterSave(data => data?.map(d => {
                                if (d?.data?.id === recordToEdit?.id) {
                                    return {
                                        ...d,
                                        data: {
                                            ...d?.data,
                                            ...formData,
                                        }
                                    };
                                }

                                return d;
                            }));
                        }}
                        onUpdated={(recordToEdit, formData) => {
                            setDocumentsToUpdateAfterSave(data => {
                                const record = data?.find(i => i?.id == recordToEdit?.id);

                                if (!record) {
                                    data.push({
                                        id: recordToEdit?.id,
                                        ...formData,
                                    });
                                } else {
                                    data = data?.map(d => {
                                        if (d?.id == recordToEdit?.id) {
                                            return {
                                                id: recordToEdit?.id,
                                                ...formData,
                                            };
                                        }
                                        return d;
                                    });
                                }
                                
                                return data;
                            });
                            setDocumentsToUpdateAfterSave(data => data?.map(d => {
                                if (d?.data?.id === recordToEdit?.id) {
                                    return {
                                        ...d,
                                        data: {
                                            ...d?.data,
                                            ...formData,
                                        }
                                    };
                                }

                                return d;
                            }));
                        }}
                        onAdded={item => {
                            // let itemData = item;

                            // optionsData?.links?.forEach(l => {
                            //     delete itemData[l?.name];
                            // });

                            setDocumentsToLinkAfterSave(d => ([
                                ...d,
                                {
                                    data: item,
                                    document_type_id: optionsData?.document_type_id,
                                    documents: optionsData?.links?.map(l => ({
                                        document_type_id: l?.document_type_id,
                                        name: l?.name,
                                    })),
                                    link_type_id: 3,
                                }
                            ]));
                        }}
                        editable={true} 
                        document_links={optionsData?.links}
                        columns={optionsData?.columns} />;
                }
            }}
        </Field>
    </Wrapper>;
}

export default FormFieldUncontrolled;

const Section = styled.div`
margin-bottom: 10px;
margin-top: 40px;
border-bottom: 1px solid #eee;
padding-bottom: 10px;
`;

const Wrapper = styled.div`
    margin-top: 20px;
`;

class CustomJiraTransformer extends JIRATransformer {
    encodeMention(node, encoder) {
        const elem = this.doc.createTextNode(`@[${node.attrs.text.replace("@", "")}](${node.attrs.id})`);
        return elem;
    }
}