import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { 
    Login, Dashboard, Space,
    Document, New, Profile, 
    Reset, Admin, AdminStructure, 
    Password, Module,
    AdminUsers, RedirectDocument,
    AdminModules, AdminModule,
    AdminCategory, AdminSpace,
    AdminPermissions, AdminRoles,
    AdminConfig, AdminWidgets,
    AdminWorkflows, FacebookCallback,
    Cms,
} from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IntlProvider } from "react-intl";
import PrivateRoute from "./components/routes/private-route";
import GuestRoute from "./components/routes/guest-route";
import ScrollToTop from "./components/nav/scroll-to-top";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locale/en.json";
import pl from "./locale/pl.json";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: en,
            },
            pl: {
                translation: pl,
            },
        },
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: "en",
        detection: {
            order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
        },
        react: {
            useSuspense: false,
        },
    });

const domain = window.location.hostname;
let WS_URL = process.env.API_URL ?? "127.0.0.1";
let forceTLS = false;
let disableStats = false;
let port = 6001;

if (domain !== "localhost") {
    WS_URL = `api.${domain}`;
    forceTLS = true;
    disableStats = true;
    port = 6002;
}

import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
    broadcaster: "pusher",
    key: "key",
    wsHost: WS_URL,
    wsPort: port,
    wssPort: port,
    forceTLS: forceTLS,
    disableStats: disableStats,
});


function App() {
    return <>
        <IntlProvider locale="pl">
            <Router>
                <ScrollToTop />
                <Switch>
                    <PrivateRoute exact path="/">
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin">
                        <Admin />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/structure">
                        <AdminStructure />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/users">
                        <AdminUsers />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/modules">
                        <AdminModules />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/modules/:module">
                        <AdminModule />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/modules/:module/:category">
                        <AdminCategory />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/modules/:module/:category/:slug">
                        <AdminSpace />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/permissions">
                        <AdminPermissions />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/roles">
                        <AdminRoles />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/config">
                        <AdminConfig />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/widgets">
                        <AdminWidgets />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/workflows">
                        <AdminWorkflows />
                    </PrivateRoute>
                    <PrivateRoute exact path="/redirect/document/:id">
                        <RedirectDocument />
                    </PrivateRoute>
                    <PrivateRoute exact path="/:module/:category/:space">
                        <Space />
                    </PrivateRoute>
                    <PrivateRoute exact path="/:module/:category/:space/new">
                        <New />
                    </PrivateRoute>
                    <PrivateRoute exact path="/:module/:category/:space/:id">
                        <Document />
                    </PrivateRoute>
                    <PrivateRoute exact path="/profile">
                        <Profile />
                    </PrivateRoute>
                    <Route exact path="/404">
                        <div>Not Found</div>
                    </Route>
                    <GuestRoute exact path="/login">
                        <Login />
                    </GuestRoute>
                    <Route exact path="/reset">
                        <Reset />
                    </Route>
                    <Route exact path="/password">
                        <Password />
                    </Route>
                    <PrivateRoute exact path="/modules/:module">
                        <Module />
                    </PrivateRoute>
                    <PrivateRoute exact path="/facebook-callback">
                        <FacebookCallback />
                    </PrivateRoute>
                    <Route exact path="/:namespace/:uuid">
                        <Cms />
                    </Route>
                    <Route path="*">
                        <div>Not found.</div>
                    </Route>
                </Switch>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Router>
        </IntlProvider>
    </>;
}

export default App;