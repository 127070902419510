import Popup from "@atlaskit/popup";
import React, { useState } from "react";
import AppSwitcherIcon from "@atlaskit/icon/glyph/app-switcher";
import Button from "@atlaskit/button";
import styled from "styled-components";
import { useModules } from "../../providers/modules-provider";
import {
    Section,
    ButtonItem,
    SideNavigation,
} from "@atlaskit/side-navigation";
import Subtask24Icon from "@atlaskit/icon-object/glyph/subtask/24";
import { Link } from "react-router-dom";
import Task24Icon from "@atlaskit/icon-object/glyph/task/24";
import useGlobalConfig from "../../hooks/use-global-config";
import IconWidget from "../widgets/icon-widget";

function AppSwitcher() {
    const [ isOpen, setIsOpen ] = useState(false);
    const { modules } = useModules();
    const { config } = useGlobalConfig("dashboard");

    return <Popup
        placement="bottom-start"
        content={() => <Wrapper>
            <SideNavigation label="project">
                {!config?.redirect && <Link to="/">
                    <ButtonItem iconBefore={<Task24Icon />}>Dashboard</ButtonItem>
                </Link>}
                {modules && modules.map(module => (
                    <Module key={module.id} module={module} onClick={() => setIsOpen(false)} />
                ))}
            </SideNavigation>
        </Wrapper>}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        trigger={triggerProps => (
            <Button iconBefore={<AppSwitcherIcon />} onClick={() => setIsOpen(v => !v)} {...triggerProps} />
        )}
    />;
}

export default AppSwitcher;

function Module({ module, onClick }) {
    return <Section>
        <Link to={`/modules/${module.slug.toLowerCase()}`} onClick={onClick}>
            <ButtonItem iconBefore={
                module?.icon ? <IconWrapper>
                    <IconWidget name={module?.icon} size="20px" />
                </IconWrapper> : <Subtask24Icon />
            }>{module.name}</ButtonItem>
        </Link>
    </Section>;
}

const Wrapper = styled.div`
    padding: 20px;
`;

const IconWrapper = styled.div`
    color: white;
    background-color: #2684ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px;
    width: 20px;
    height: 20px;
`;