import React, { useState, useCallback } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import toBoolean from "./to-boolean";
import FormTable from "../components/forms/form-table";
import DOMPurify from "dompurify";
import DocumentTable from "../components/documents/document-table";
import DisplayDocumentByType from "../components/documents/display-document-by-type";
import Spinner from "../components/ui/spinner";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function CellValue({ value, document, field, editable = false }) {
    const { t } = useTranslation();
    const defaultValue = editable ? <EditablePlaceholder>{t("cell_value_placeholder")}</EditablePlaceholder> : "-";

    const Wysywig = ({ value }) => {
        if (!value) return defaultValue;
        
        const html = DOMPurify.sanitize(value);
        // eslint-disable-next-line react/react-in-jsx-scope
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };

    if (toBoolean(field?.workflow) && !value) {
        return <Placeholder>
            <Spinner size="xsmall" />
        </Placeholder>;
    }

    if (field.field.type === "checkbox") return <Checkbox
        isChecked={toBoolean(value)} label={field?.label ?? field?.options?.label}
    />;

    if (field.field.type === "wysywig") return <Wysywig value={value} />;

    if (field.field.type === "select") {
        const label = field.options
            .map(i => ({ label: i.name, value: i.value }))
            .find(i => i.value == value)
            ?.label;

        return <span>{label || defaultValue}</span>;
    }

    if (field.field.type === "multiselect") {
        const values = document?.rawValues?.filter(i => i?.document_type_field_id === field?.id) || [];
        const labels = values
            ?.map(i => {
                const option = field?.options?.find(o => o?.value === i?.value);
                return { label: option?.name, value: option?.value };
            })
            ?.map(i => i?.label)
            ?.join(", ");

        return <span>{labels || "-"}</span>;
    }

    if (field.field.type === "table") {
        return <FormTable table_column={field.table_column} label={field.label} editable={false} columns_values={document.columns_values} />;
    }

    if (field.field.type === "document-type") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            return "-";
        }
        if(value === "") return "-";
        return <DisplayDocumentByType id={parseInt(value)} documentTypeId={optionsData?.documentTypeId} />;
    }

    if (field.field.type === "list") {
        const rawOptions = field.options?.[0];
        if (!rawOptions) return "-";
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            return "-";
        }

        return <DocumentTable 
            document={document} 
            xId={optionsData?.document_type_id} 
            name={optionsData?.label}
            summary={optionsData?.summary}
            editable={false} 
            document_links={optionsData?.links}
            columns={optionsData?.columns}
        />;
    }

    const rawOptions = field.options?.[0];
    let optionsData = {};
    try {
        optionsData = JSON.parse(rawOptions);
    } catch (e) {
        //
    }

    if (optionsData?.format === "url" && value?.length > 0) {
        return <div>
            <a href={value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    } else if (optionsData?.format === "email" && value?.length > 0) {
        return <div>
            <a href={"mailto:" + value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    } else if (optionsData?.format === "tel" && value?.length > 0) {
        return <div>
            <a href={"tel:" + value} target="_blank" rel="noreferrer">{value ?? "-"}</a>
        </div>;
    }

    if (field.field.type === "password") {
        return <div>-</div>;
    }

    if (value?.length === 0) {
        return <div>{defaultValue}</div>;
    }

    return <div>{value ?? defaultValue}</div>;
}



const SpinnerWrapper = styled.div`
    text-align: left;
`;

const EditablePlaceholder = styled.small`
    opacity: 0.6;
    font-style: italic;
`;

const Placeholder = styled.div`
    width: 100%;
    height: 25px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0px 2px 1px 0px #eee;
    padding: 5px;
    margin: 7px 0;
    background: rgba(0,0,0,0.005);
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, red 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
`;
