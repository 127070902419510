/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { Field } from "@atlaskit/form";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import TextField from "@atlaskit/textfield";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { toast } from "react-toastify";
import SelectCategory from "../categories/select-category";
import TextArea from "@atlaskit/textarea";
import useSpace from "../../hooks/use-admin-space";
import { useTranslation } from "react-i18next";

export default function EditSpace({ space, onClose, onSaved, category }) {
    const { t } = useTranslation();
    const { run, isPending, error, isError } = useAsync();
    const [ name, setName ] = useState(space.name);
    const [ slug, setSlug ] = useState(space.slug);
    const [ icon, setIcon ] = useState(space.icon);
    const [ position, setPosition ] = useState(space.position ?? 0);
    const [ selectedCategory, setSelectedCategory ] = useState(category?.id);
    const [ config, setConfig ] = useState(() => {
        try {
            return JSON.stringify(space.config ?? {}) ?? "{}";
        } catch (e) {
            return "{}";
        }
    });

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="button" isLoading={isPending} onClick={handleSubmit}>
                    {t("admin_edit_space_save")}
                </Button>
                <Button type="button" isDisabled={isPending} onClick={onClose}>
                    {t("admin_edit_space_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    const handleSubmit = () => {
        const formData = {
            name,
            slug,
            category_id: selectedCategory,
            config: JSON.parse(config),
            position,
            icon,
        };

        run(apiClient(`spaces/${space.slug}`, { method: "PATCH", data: formData}))
            .then(response => {
                onSaved(response);
                onClose();
                toast.success(t("admin_edit_space_success"));
            })
            .catch(() => {
                toast.error(t("admin_edit_space_error"));
            });
    };

    return <ModalTransition>
        <Modal
            onClose={onClose}
            heading={t("admin_edit_space_heading")}
            scrollBehavior="outside"
            components={{
                Footer: footer,
            }}
        >
            {isError && <SectionMessageWrapper>
                <SectionMessage title={t("admin_edit_space_error_heading")} appearance="error">
                    <p>{error?.message}</p>
                    {error?.errors && <ul>
                        {Object.keys(error.errors).map(key => (
                            <li key={key}>{error.errors[key]}</li>
                        ))}
                    </ul>}
                </SectionMessage>
            </SectionMessageWrapper>}
            <Field name="name" label={t("admin_edit_space_name")} isRequired autoFocus isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={name} onChange={e => setName(e.target.value)} isInvalid={error?.errors?.name} autoComplete={false} />}
            </Field>
            <Field name="slug" label={t("admin_edit_space_slug")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={slug} onChange={e => setSlug(e.target.value)} isInvalid={error?.errors?.slug} autoComplete={false} />}
            </Field>
            <Field name="category" label={t("admin_edit_space_category")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <SelectCategory value={selectedCategory} onChange={e => setSelectedCategory(e?.value)} />}
            </Field>
            <Field name="config" label={t("admin_edit_space_config")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextArea
                    {...fieldProps}
                    isMonospaced
                    value={config}
                    onChange={e => setConfig(e?.target?.value)}
                    minimumRows={5}
                />}
            </Field>
            <Field name="position" label={t("create_module_position")} isRequired isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={position} onChange={e => setPosition(e.target.value)} isInvalid={error?.errors?.position} autoComplete={false} />}
            </Field>
            <Field name="icon" label={t("create_module_icon")} isDisabled={isPending}>
                {({ fieldProps }) => <TextField {...fieldProps} value={icon} onChange={e => setIcon(e.target.value)} isInvalid={error?.errors?.icon} autoComplete={false} />}
            </Field>
        </Modal>
    </ModalTransition>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 0px;
    font-size: 13px;
`;
