import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { useLocation } from "react-router-dom";
import ModulesProvider from "../../providers/modules-provider";

function PrivateRoute({ children, ...props }) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    if (!user) {
        return <Redirect to={"/login?redirect=" + pathname} />;
    }

    return <Route {...props}>
        <ModulesProvider>
            {children}
        </ModulesProvider>
    </Route>;
}

export default PrivateRoute;