import React, { useEffect, useState } from "react";
import EditDocumentForm from "./edit-document-form";
import styled from "styled-components";
import CellValue from "../../utils/cell-value";
import Lozenge from "@atlaskit/lozenge";
import toBoolean from "../../utils/to-boolean";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import HipchatChevronUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-up";
import Tooltip from "@atlaskit/tooltip";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import InlineEdit from "../forms/inline-edit";
import { useLocation } from "react-router-dom";
import Widgets from "../widgets/widgets";
import Documents from "./documents";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";

function useHash() {
    return useLocation().hash?.slice(1);
}

function Document({ document, fields, editMode, error, onChange, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave, setDocumentsToUpdateAfterSave, data = {}, propagateFields, reload }) {
    const [ hidden, setHidden ] = useState([]);
    const currentSection = useHash();

    useEffect(() => {
        const h = [];
        fields?.filter(f => f?.field?.type === "section")?.forEach(field => {
            if (field?.name != currentSection) {
                h.push(field?.sectionId);
            }
        });
        setHidden(h);
    }, [ fields?.length, document?.id, currentSection ]);

    const onSectionToggle = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [ ...h, sectionId ]);
        }
    };

    return <>
        {fields
            .filter(i => !toBoolean(i.hidden))
            .sort((a, b) => a.position - b.position).map(field => {
                const sectionHidden = hidden?.includes(field?.sectionId);

                if (sectionHidden || (!!currentSection && !field?.sectionId)) return null;
                
                if (field.field.type === "section") {
                    return <Section>
                        <SectionTitle>{field.label}</SectionTitle>
                    </Section>;
                }

                const value = document.values[field.id];
                const inputProps = {
                    key: field.id,
                    name: field.name,
                    isRequired: (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
                    defaultValue: field?.field?.type === "checkbox" ? toBoolean(document.values[field.id]) : document.values[field.id],
                    isInvalid: error?.errors[field.name],
                    table_column: field?.table_column || [],
                    columns_values: document?.columns_values,
                    isDisabled: toBoolean(field?.read_only ?? 0),
                    rawValues: document?.rawValues || [],
                    autoFocus: false
                };

                const help = field?.help?.length > 0 &&
        <HelpWrapper>
            <Tooltip content={field?.help}>
                <QuestionCircleIcon size="small" />
            </Tooltip>
        </HelpWrapper>;

                return <Row key={field.id}>
                    {field?.field?.type === "checkbox" 
                || field?.field?.type === "table" 
                || field?.field?.type === "message" 
                || field?.field?.type === "widgets" ? null : <Label>
                            <Wrapper>
                                {field.label}{(!!field?.required || !!field?.must_have) && <Required>*</Required>} {help}
                            </Wrapper></Label>}
                    <Value margin={field?.field?.type === "message" ? 0 : 30}>
                        <InlineEdit 
                            value={value} 
                            reload={reload}
                            onSaved={() => reload && reload()}
                            document={document} 
                            isRequired={!!field?.required || !!field?.must_have}
                            propagateFields={propagateFields}
                            field={field} 
                            fields={fields}
                            inputProps={inputProps} 
                            editable={editMode} />
                    </Value>
                </Row>;
            })}


        {document?.linkable.map(l => {
            if (decodeURI(currentSection) == l?.name) {
                const optionsData = l?.config;
                return <>
                    {optionsData?.widgets && document?.document_type?.space?.category?.module && <Widgets widgets={optionsData?.widgets} module={document?.document_type?.space?.category?.module?.name} documentId={document?.id} />}
                    {optionsData?.message && <SectionMessageWrapper>
                        <SectionMessage
                            title={optionsData?.message?.title}
                            appearance={optionsData?.message?.appearance}
                            actions={optionsData?.message?.actions?.map(a => (
                                <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                            ))}
                        >
                            {optionsData?.message?.body}
                        </SectionMessage>
                    </SectionMessageWrapper>}
                    <Documents 
                        document={document} 
                        linkTypeId={optionsData?.link_type_id} 
                        documentTypeId={l?.id} 
                        document_links={optionsData?.links}
                        editMode={editMode}
                        filtersEnabled
                        onReload={reload}
                        currentSection={currentSection}
                        noColumnOrder
                        showColumnMenuTool
                        colorField={optionsData?.colorField}
                        columnsWidths={optionsData?.columnsWidths ?? {}}
                        defaultColumnWidth={optionsData?.defaultColumnWidth}
                        slug={l?.space}
                        columns={optionsData?.columns} />
                </>;
            }
        })}
    </>;
}

export default Document;

const Row = styled.div`
    margin-top: 8px;
`;

const Label = styled.label`
font-size: 1em;
font-style: inherit;
font-weight: 600;
letter-spacing: -0.003em;
margin-top: 16px;
line-height: 24px;
color: #6B778C;
overflow-wrap: break-word;
min-width: 0px;
`;

const Value = styled.div`
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    width: auto;
    margin-bottom: ${props => props.margin}px;
    font-size: 1.0rem;
    padding-top: 8px;
`;

const Section = styled.div`
margin-bottom: 10px;
margin-top: 40px;
border-bottom: 1px solid #eee;
padding-bottom: 10px;
display: flex;
cursor: pointer;
`;

const SectionTitle = styled.h4`
    flex: 1;
    padding-right: 20px;
`;

const SectionIcon = styled.div`
`;

const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
`;

const HelpWrapper = styled.div`
    padding: 0 5px 0;
    cursor: help;
`;

const SectionMessageWrapper = styled.div`
margin-top: 20px;
`;

const Required = styled.span`
font-size: 10px;
color: red;
padding: 0 6px;
`;