/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import NewDocumentForm from "./new-document-form";
import Form from "@atlaskit/form";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import prepareFormData from "../../utils/prepare-form-data";
import { toast } from "react-toastify";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useDocument from "../../hooks/use-document";
import LinkedDocuments from "./linked-documents";
import { Link } from "react-router-dom";
import slugify from "slugify";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import useDocumentFields from "../../hooks/use-document-fields";
import FormFieldUncontrolled from "../forms/form-field-uncontrolled";
import toBoolean from "../../utils/to-boolean";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function EditEventModal({ open, onClose, onSubmit, onEdit, documentTypeId, resourceId = null, startDate = null, endDate = null, documentLinkTypeId = null, eventId = null }) {
    const { run, isPending, error, isError: isCreatingError } = useAsync();
    const { document, isPending: loadingDocument, reload: reloadDocument } = useDocument(eventId);
    const [ eventInitialValues, setEventInitialValues ] = useState({});
    const { isPending: isPendingFields, fields } = useDocumentFields(documentTypeId);
    const { t  } = useTranslation();

    useEffect(() => {
        if (!document && !document?.id && !document?.rawValues) return;

        const fields = document?.document_type?.fields?.reduce((acc, f) => {
            acc[f.id] = f.name;
            return acc;
        }, {});

        setEventInitialValues(document?.rawValues?.reduce((acc, v) => {
            const fieldId = v?.document_type_field_id;
            const key = fields[fieldId];
            acc[key] = v?.value;
            return acc;
        }, {}));
    }, [ document?.id ]);

    const isLoadingDocument = eventId && loadingDocument ;

    const handleSubmit = data => 
    {
        const formData = prepareFormData(data);
        return editEvent(formData);
    };

    const editEvent = (formData) => {
        run(apiClient(`workflow/document/${eventId}`, { method: "PATCH", data: formData}))
            .then(() => {
                onEdit && onEdit();
            })
            .catch(error => {
                toast.error(error?.message || t("edit_event_modal_save_document_error"));
            });
    };

    const footer = (props) => (
        <ModalFooter showKeyline={props.showKeyline}>
            <ButtonGroup>
                <Button appearance="primary" type="submit" isLoading={isPending}>
                    {eventId ? t("create_event_modal_save") : t("create_event_modal_create")}
                </Button>
                <Button type="button" isLoading={isPending} onClick={onClose}>
                    {t("create_event_modal_cancel")}
                </Button>
            </ButtonGroup >
        </ModalFooter>
    );

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    isLoading={isLoadingDocument || isPendingFields}
                    onClose={() => {
                        if (isPending) return;
                        onClose();
                    }}
                    heading={ eventId ? <div>
                        {document?.id && <DocumentLink document={document} />}
                        <div>{t("create_event_modal_edit_heading")}</div>
                    </div> : t("create_event_modal_create_heading") }
                    components={{
                        Container: ({ children, className }) => (
                            <Form onSubmit={handleSubmit}>
                                {({ formProps }) => (
                                    <form {...formProps}>
                                        {children}
                                    </form>
                                )}
                            </Form>
                        ),
                        Footer: footer,
                    }}
                    scrollBehavior="outside"
                >
                    <Wrapper>
                        {isCreatingError && <SectionMessageWrapper>
                            <SectionMessage title={t("create_event_modal_error_heading")} appearance="error">
                                <p>{error?.message}</p>
                                {error?.errors && <ul>
                                    {Object.keys(error.errors).map(key => (
                                        <li key={key}>{error.errors[key]}</li>
                                    ))}
                                </ul>}
                            </SectionMessage>
                        </SectionMessageWrapper>}
                        {fields?.filter(f => !toBoolean(f.hidden) && !toBoolean(f.read_only)).filter(f => toBoolean(f.quick_form)).map(field => {
                            const inputProps = {
                                key: field.id,
                                name: field.name,
                                label: field?.field?.type === "checkbox" ? null : field.label,
                                isRequired: toBoolean(field.required) || toBoolean(field?.must_have),
                                defaultValue: field.field.type === "date" ? format(new Date(), "yyyy-MM-dd") : undefined,
                                isInvalid: error && error?.errors ? error?.errors[field.name] : false,
                                options: field?.options || [],
                                table_column: field?.table_column || [],
                                isDisabled: toBoolean(field?.read_only),
                            };

                            const initialValues = {
                                start_date: startDate,
                                end_date: endDate,
                                ...eventInitialValues,
                            };

                            if (initialValues[field.name]) {
                                inputProps.defaultValue = initialValues[field.name];
                            }

                            return <FormFieldUncontrolled key={field.id} inputProps={inputProps} field={field} />;
                        })}
                        {document?.id && <LinkedDocuments document={document} onRefresh={reloadDocument} />}
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const SectionMessageWrapper = styled.div`
    margin-top: 50px;
    font-size: 13px;
`;

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

function DocumentLink({ document }) {
    const space = document?.document_type?.space;
    if (!space) return null;
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const name = document?.values[nameField.id];
    if (!name) return null;
    const path = `${slugify(space?.category?.module?.name)}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
    return <DocumentLinkWrapper>
        <Link to={`/${path}/${document.id}`}>
            <DocumentLinkRow>
                <DocumentLinkIcon>
                    <Page16Icon />
                </DocumentLinkIcon>

                <Category>
                    {space?.category?.module?.alias}/
                    {space?.category?.name}/
                    {space?.name} 
                </Category>
                {name}
            </DocumentLinkRow>
        </Link>
    </DocumentLinkWrapper>;
}

const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-bottom: 15px;
`;

const DocumentLinkRow = styled.div`
    display: flex;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
`;

const Category = styled.span`
color: rgb(137, 147, 164);
text-decoration: none;
outline: currentcolor none medium;
margin-left: 8px;
color: rgb(137, 147, 164);
font-weight: 600;
font-size: 12px;
text-decoration: none;
white-space: nowrap;
margin-right: 10px;
`;