import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../providers/auth-provider";
import Avatar from "@atlaskit/avatar";
import Form, { FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor, WithEditorActions, EditorContext } from "@atlaskit/editor-core";
import { JIRATransformer } from "@atlaskit/editor-jira-transformer";
import { resourceProvider } from "../../utils/mention-resource";
import { useTranslation } from "react-i18next";
const mentionProvider = Promise.resolve(resourceProvider);

function CommentForm({ documentId, onAdded }) {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { isError, error, isPending, run } = useAsync();
    const [ comment, setComment ] = useState("");
    const { run: runUsers } = useAsync();
    const [ mentions, setMentions ] = useState([]);

    const handleSubmit = (e, actions) => {
        e.preventDefault();
        e.stopPropagation();
        if (comment.length === 0) return;

        const regex = /@\[.*?\]\((\d+)\)/g;
        const matches = comment.matchAll(regex);
        const mentions = Array.from(matches).map(match => match[1]);

        run(apiClient(`document/${documentId}/comment`, { data: { 
            message: comment,
            mentions,
        }}))
            .then(() => {
                setComment("");
                toast.success(t("comment_form_added"));
                onAdded();
                actions.clear();
            })
            .catch(() => toast.error(t("comment_form_fail")));
    };

    const mentionEncoder = (userId) => `/secure/ViewProfile?name=${userId}`;

    return <Wrapper>
        <AvatarWrapper>
            <Avatar src={user?.avatar_url} size="medium" />
        </AvatarWrapper>
        <FormWrapper>
            <EditorContext >
                <WithEditorActions
                    render={actions => (
                        <Form isDisabled={isPending}>
                            {({ formProps }) => (
                                <form {...formProps} onSubmit={e => handleSubmit(e, actions)}>
                                    <Editor onChange={async e => {
                                        const value = await actions.getValue();
                                        setComment(value);
                                    }}
                                    allowExpand
                                    allowConfluenceInlineComment
                                    autoScrollIntoView
                                    value={comment}
                                    contentTransformerProvider={schema =>
                                        new CustomJiraTransformer(schema, { mention: mentionEncoder })
                                    }
                                    mentionProvider={mentionProvider}
                                    mentionInsertDisplayName={false}
                                    appearance="comment" />
                                    <FormFooter>
                                        <FormFooterWrapper>
                                            <Spacer />
                                            <ButtonGroup>
                                                <Button type="submit" appearance="primary" isLoading={isPending} isDisabled={comment?.length === 0}>
                                                    {t("comment_form_button")}
                                                </Button>
                                            </ButtonGroup>
                                        </FormFooterWrapper>
                                    </FormFooter>
                                </form>
                            )}
                        </Form>
                    )}
                />
            </EditorContext>
        </FormWrapper>
    </Wrapper>;
}

export default CommentForm;

const Wrapper = styled.div`
    display: flex;
    margin: 20px 0 0;
    margin-top: 20px;
    padding: 20px 25px 20px;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
`;

const FormWrapper = styled.div`
    flex-grow: 1;
    padding-left: 15px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const FormFooterWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: -10px;
`;

const AvatarWrapper = styled.div`
    padding-top: 10px;
`;

class CustomJiraTransformer extends JIRATransformer {
    encodeMention(node, encoder) {
        const elem = this.doc.createTextNode(`@[${node.attrs.text.replace("@", "")}](${node.attrs.id})`);
        return elem;
    }
}