import React, { useState, useEffect } from "react";
import AtlaskitInlineEdit from "@atlaskit/inline-edit";
import CellValue from "../../utils/cell-value";
import FormField from "./form-field";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@atlaskit/checkbox";
import Signature from "./signature";
import styled from "styled-components";
import EditIcon from "@atlaskit/icon/glyph/edit-filled";
import Documents from "../documents/documents";
import AbsoluteSpinner from "../ui/absolute-spinner";
import toBoolean from "../../utils/to-boolean";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import Widgets from "../widgets/widgets";

export default function InlineEdit({ value: val, document, field, inputProps, editable = false, onSaved, fields, reload, root }) {
    const [value, setValue] = useState(val);
    const [editValue, setEditValue] = useState(val);
    const [propagated, setPropagated] = useState([]);
    const [hover, setHover] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const isEditable = editable && toBoolean(!field?.read_only);

    useEffect(() => {
        setValue(val);
    }, [val]);
    const { t } = useTranslation();
    const save = (saveValue) => {
        setIsPending(true);
        setValue(saveValue);
        setEditValue(saveValue);

        if (field.field.type === "password") {
            setValue("");
            setEditValue("");
        }

        apiClient(`workflow/document/${document.id}`, {
            method: "PATCH",
            data: {
                [field.name]: saveValue
            }
        })
            .then(() => {
                if (!fields) return Promise.resolve();

                return Promise.all(propagated?.map(prop => {
                    const propagatingField = prop.field;
                    const valuesMap = prop.propagated?.values?.reduce((acc, i) => {
                        acc[i?.name] = i?.value;
                        return acc;
                    }, {});

                    const propagatingFieldOptions = parseOptions(propagatingField?.options);
                    const documentTypeId = propagatingFieldOptions?.documentTypeId;
                    const toEdit = {};

                    fields.map(f => {
                        const fieldOptions = parseOptions(f?.options);

                        if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                            const fieldName = fieldOptions?.document_type?.field_name;
                            const value = valuesMap[fieldName];

                            toEdit[f?.name] = value;
                        }

                    });

                    if (Object.keys(toEdit).length === 0) return Promise.resolve();

                    return apiClient(`workflow/document/${document.id}`, {
                        method: "PATCH",
                        data: toEdit,
                    }).catch(() => { });
                }));
            })
            .then(() => {
                setPropagated([]);
            })
            .then(() => {
                setHover(false);
                onSaved && onSaved(saveValue);
            })
            .catch(() => {
                toast.error(t("inline_edit_error"));
            })
            .finally(() => setIsPending(false));
    };

    if (field.field.type === "checkbox") {
        return <Checkbox
            defaultChecked={inputProps.defaultValue}
            defaultValue={true}
            value={value}
            onChange={e => {
                save(e?.target?.checked);
            }}
            label={field?.label ?? field?.options?.label} />;
    }

    if (field.field.type === "widgets") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }
        return <Widgets widgets={optionsData?.widgets} documentId={document?.id} />;
    }

    if (field.field.type === "signature") {
        return <Signature field={field} value={editValue} onChange={save} editable={isEditable} />;
    }

    if (field.field.type === "message") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        if (!optionsData?.body) {
            return <UserSectionMessage
                title={optionsData?.title}
                appearance={optionsData?.appearance}
                value={value}
            />;
        }

        return <SectionMessageWrapper>
            <SectionMessage
                title={optionsData?.title}
                appearance={optionsData?.appearance}
                actions={optionsData?.actions?.map(a => (
                    <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                ))}
            >
                {optionsData?.body}
            </SectionMessage>
        </SectionMessageWrapper>;
    }

    if (field.field.type === "list") {
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }
        return <Documents
            document={document}
            linkTypeId={optionsData?.link_type_id}
            documentTypeId={optionsData?.document_type_id}
            document_links={optionsData?.links}
            editMode={isEditable}
            colorField={optionsData?.colorField}
            columnsWidths={optionsData?.columnsWidths ?? {}}
            defaultColumnWidth={optionsData?.defaultColumnWidth}
            createButton
            slug={optionsData?.space}
            columns={optionsData?.columns} />;
    }

    if (!isEditable) {
        return <CellValue value={value} document={document} field={field} editable={isEditable} />;
    }

    return <InlineWrapper>
        <AtlaskitInlineEdit
            defaultValue={editValue}
            isRequired={inputProps.isRequired}

            editView={({ errorMessage, ...fieldProps }) => (
                <FormField {...fieldProps}
                    autoFocus
                    noLabel
                    root={root}
                    field={field}
                    value={editValue}
                    propagateFields={(propagatingField, valuesMap) => {
                        // if (!fields) return;

                        // console.log("PROPAGATE", propagatingField, valuesMap);

                        // const propagatingFieldOptions = parseOptions(propagatingField?.options);
                        // const documentTypeId = propagatingFieldOptions?.documentTypeId;
                        // const toEdit = {};

                        // fields.map(f => {
                        //     const fieldOptions = parseOptions(f?.options);

                        //     if (fieldOptions?.document_type?.id == documentTypeId && fieldOptions?.document_type?.name === propagatingField?.name) {
                        //         const fieldName = fieldOptions?.document_type?.field_name;
                        //         const value = valuesMap[fieldName];

                        //         console.log(fieldName, value);
                        //         toEdit[fieldName] = value;
                        //     }

                        // });

                        // if (toEdit?.length === 0) return;

                        // apiClient(`workflow/document/${document.id}`, { 
                        //     method: "PATCH",
                        //     data: toEdit,
                        // }).then(() => reload && reload());
                    }}
                    document={document}
                    type={field.field.type == "password" ? "password" : "text"}
                    onChange={(field, val, propagated) => {
                        setEditValue(val);
                        setPropagated(p => {
                            const filtered = p?.filter(i => i?.name != field?.name);
                            filtered?.push({
                                name: field?.name,
                                field,
                                propagated,
                            });
                            return filtered;
                        });
                    }}
                    inputProps={inputProps}
                />
            )}
            readView={() => (
                <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <FlexMe>
                        <CellValue value={value} document={document} field={field} editable={isEditable} />
                    </FlexMe>
                    {hover && <EditIcon size="small" />}
                </Wrapper>
            )}
            onConfirm={(v, e) => {
                setValue(v);
                save(v);
            }}
            onCancel={() => {
                setEditValue(value);
                setHover(false);
            }}
        />
        {isPending && <AbsoluteSpinner />}
    </InlineWrapper>;
}

const InlineWrapper = styled.div`
margin-top: -8px;
`;

const Wrapper = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px;
    padding: 5px 10px;
`;

const FlexMe = styled.div`
    flex: 1
    margin-right: 10px;
`;

const SectionMessageWrapper = styled.div`
margin-top: 10px;
`;

function UserSectionMessage({ title, appearance, value }) {
    if (!value || value?.length === 0) return null;

    return <SectionMessageWrapper>
        <SectionMessage
            title={title}
            appearance={appearance}
        >
            {value}
        </SectionMessage>
    </SectionMessageWrapper>;
}

function parseOptions(opt) {
    const optString = opt?.[0] || "{}";

    try {
        return JSON.parse(optString);
    } catch (e) {
        return {};
    }
}