import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { Radio } from "@atlaskit/radio";
import Lozenge from "@atlaskit/lozenge";
import useOrder from "../../hooks/use-order";
import Spinner from "../../components/ui/spinner";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PaymentStatus() {
    const query = useQuery();
    const orderID = query.get("OrderID");
    const hash = query.get("Hash");
    const { order, isPending, pay } = useOrder(orderID);

    if (isPending) {
        return <Spinner />;
    }

    return <>
        <Helmet>
            <title>{order?.name}</title>
        </Helmet>
        <Wrapper>
            <Left>
                <Header>Zamówienie nr {order.name}</Header>
                <Section>
                    <SectionHeader></SectionHeader>
                </Section>
            </Left>
            <Right>
                <Summary>
                    <h3 style={{ marginBottom: "25px" }}>Podsumowanie</h3>

                    {order?.package?.orders?.map((order, i) => (
                        <>
                            <Flex>
                                <Main>
                                    <h4>Zamówienie #{i + 1}</h4>
                                </Main>
                                <div>
                                    {(order?.status == "W toku" || order?.status == null || order?.status === "") ? <Lozenge appearance="inprogress">W toku</Lozenge> : null}
                                    {order?.status == "Anulowane" ? <Lozenge appearance="removed">Anulowane</Lozenge> : null}
                                    {(order?.status != "W toku" && order?.status != null && order?.status !== "") && order?.status != "Anulowane" ? <Lozenge appearance="success">Zapłacone</Lozenge> : null}

                                </div>
                            </Flex>
                            <SummaryList style={{ opacity: "0.5" }}>
                                {order?.positions?.map(position => (
                                    <FlexListItem key={position?.name}>
                                        <Main>{position?.count} x {position?.name}</Main>
                                        <RightAlign>{parseFloat(position.sale) > 0 ? parseFloat(parseFloat(position?.count) * parseFloat(position.sale)) : parseFloat(parseFloat(position?.count) * parseFloat(position.price))}zł</RightAlign>
                                    </FlexListItem>
                                ))}
                            </SummaryList>
                        </>
                    ))}
                </Summary>
            </Right>
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
padding: 20px;
@media (min-width: 900px) {
    padding: 40px;
    display: flex;
    max-width: 1200px;
    margin: 0px auto;
}
`;

const Left = styled.div`
flex: 3;
`;

const Right = styled.div`
flex: 2;

margin-top: 40px;
@media (min-width: 900px) {
    padding: 20px;
    padding-left: 100px;
}
`;

const Header = styled.h1`
font-size: 25px;
margin: 0;
padding: 0;
`;


const Section = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid #eee;
`;

const SectionHeader = styled.h3`
font-size: 16px;
margin-bottom: 10px;
`;

const Summary = styled.div`
    background: #F5F5F7;
    padding: 25px;
    border-radius: 5px;
`;

const SummaryList = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
`;

const SummaryPrice = styled.div`

`;

const Flex = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexListItem = styled.div`
display: flex;
align-items: flex-end;
margin: 10px 0;
`;

const FlexLg = styled.div`
@media (min-width: 900px) {
    display: flex;
    align-items: flex-end;
}
`;

const Main = styled.div`
    flex: 1;
`;

const SummaryPriceHighlight = styled.h2`
`;

const OpenPackageSection = styled.div`
    background: #F5F5F7;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0 0;
`;

const Block15Lg = styled.div`
@media (min-width: 900px) {
    width: 15px;
    height: 100%;
}
`;

const RightAlign = styled.div`
    text-align: right;
`;