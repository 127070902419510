import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/LogoErp.svg";

export default function AuthLayout({ children }) {
    return <>
        <LogoWrapper>
            <a href="https://erpspace.io">
                <Logo />
            </a>
        </LogoWrapper>
        <Wrapper>
            {children}
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoWrapper = styled.div`
padding: 20px;
position: absolute;
left: 0;
top: 0;
`;