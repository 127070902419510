import React, { useEffect, useState } from "react";
import {
    Section,
    HeadingItem,
    NestableNavigationContent,
    SideNavigation,
    CustomItem,
    NestingItem,
    ButtonItem,
    NavigationHeader,
    Header,
} from "@atlaskit/side-navigation";
import styled from "styled-components";
import { Link, useRouteMatch, useParams, useLocation } from "react-router-dom";
import DepartmentIcon from "@atlaskit/icon/glyph/department";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import DetailViewIcon from "@atlaskit/icon/glyph/detail-view";
import LockIcon from "@atlaskit/icon/glyph/lock";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/admin-api-client";
import useAdminModules from "../../hooks/use-admin-modules";
import useAdminCategories from "../../hooks/use-admin-categories";
import useAdminSpaces from "../../hooks/use-admin-spaces";
import PersonWithTickIcon from "@atlaskit/icon/glyph/person-with-tick";
import ChevronLeftCircleIcon from "@atlaskit/icon/glyph/chevron-left-circle";
import ChevronRightCircleIcon from "@atlaskit/icon/glyph/chevron-right-circle";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import { useTranslation } from "react-i18next";
import ComponentIcon from "@atlaskit/icon/glyph/component";
import RoadmapIcon from "@atlaskit/icon/glyph/roadmap";

function AdminSidebar() {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { module, category, slug } = useParams();
    const [ stack, setStack ] = useState(() => {
        if (module && category) {
            return [ "modules", `modules/${module}`.toLowerCase(), `modules/${module}/${category}`.toLowerCase() ];
        }

        if (module && !category) {
            return [ "modules", `modules/${module}`.toLowerCase() ];
        }

        if (match.path === "/admin/modules") {
            return [ "modules" ];
        }

        return [];
    }, []);
    const [ visible, setVisible ] = useState(() => localStorage.getItem("menubar.visible") === "true");
    const toggle = () => {
        localStorage.setItem("menubar.visible", !visible);
        setVisible(v => !v);
    };

    const { modules } = useAdminModules();
    const currentModule = modules?.find(i => i?.name?.toLowerCase() === module?.toLowerCase());
    const { categories } = useAdminCategories(currentModule);
    const { spaces } = useAdminSpaces(module, category);    

    return <Wrapper visible={visible}>
        <ToggleButton visible={visible} toggle={toggle} />
        <ToggleBorder visible={visible} />
        <InnerWrapper>
            <SideNavigation label="project">
                <NestableNavigationContent stack={stack} onChange={setStack}>
                    <HeadingItem>
                        {t("admin_heading")}
                    </HeadingItem>
                    <Section>
                        <CustomItem 
                            component={Link} 
                            to="/admin/users" 
                            isSelected={match.path == "/admin/users"}
                            iconBefore={<PeopleGroupIcon />}>
                            {t("admin_menu_users")}
                        </CustomItem>
                        <NestingItem 
                            title={t("admin_menu_modules")}
                            id="modules"
                            to="/admin/modules"
                            component={Link} 
                            iconBefore={<DetailViewIcon />}>
                            <HeadingItem>
                                {t("admin_menu_modules")}
                            </HeadingItem>
                            {modules && modules.map(m => 
                                <NestingItem
                                    key={m.id}
                                    id={`modules/${m?.name}`.toLowerCase()}
                                    to={`/admin/modules/${m?.name?.toLowerCase()}`}
                                    component={Link} 
                                    title={m?.alias}
                                >
                                    <HeadingItem>{m.alias}</HeadingItem>
                                    <Section>
                                        {categories && categories.map(c =>(
                                            <NestingItem
                                                key={c.id}
                                                id={`modules/${m?.name}/${c.slug}`.toLowerCase()}
                                                to={`/admin/modules/${m?.name?.toLowerCase()}/${c.slug}`}
                                                component={Link} 
                                                title={c.name}
                                            >
                                                <HeadingItem>{c.name}</HeadingItem>
                                                <Section>
                                                    {spaces && spaces.map(s => {
                                                        const uri = `/admin/modules/${m?.name?.toLowerCase()}/${category}/${s.slug}`.toLowerCase();
                                                        return <CustomItem key={s.id} component={Link} to={uri} isSelected={s.slug == slug}>{s.name}</CustomItem>;
                                                    })}
                                                </Section>
                                            </NestingItem>
                                        ))}
                                    </Section>
                                </NestingItem>)}
                        </NestingItem>
                        <CustomItem 
                            component={Link} 
                            to="/admin/roles" 
                            isSelected={match.path == "/admin/roles"}
                            iconBefore={<PersonWithTickIcon />}>
                            {t("admin_menu_roles")}
                        </CustomItem>
                        <CustomItem 
                            component={Link} 
                            to="/admin/permissions" 
                            isSelected={match.path == "/admin/permissions"}
                            iconBefore={<LockIcon />}>
                            {t("admin_menu_permissions")}
                        </CustomItem>
                        <CustomItem 
                            component={Link} 
                            to="/admin/widgets" 
                            isSelected={match.path == "/admin/widgets"}
                            iconBefore={<ComponentIcon />}>
                            {t("admin_menu_widgets")}
                        </CustomItem>
                        <CustomItem 
                            component={Link} 
                            to="/admin/workflows" 
                            isSelected={match.path == "/admin/workflows"}
                            iconBefore={<RoadmapIcon />}>
                            {t("admin_menu_workflows")}
                        </CustomItem>
                        <CustomItem 
                            component={Link} 
                            to="/admin/config" 
                            isSelected={match.path == "/admin/config"}
                            iconBefore={<SettingsIcon />}>
                            {t("admin_menu_config")}
                        </CustomItem>
                    </Section>
                </NestableNavigationContent>
            </SideNavigation>
        </InnerWrapper>
    </Wrapper>;
}

export default AdminSidebar;


function ToggleButton({ visible, toggle }) {
    return <ToggleButtonWrapper onClick={toggle}>
        {visible 
            ? <ChevronLeftCircleIcon size={32} primaryColor="white" secondaryColor="black" /> 
            : <ChevronRightCircleIcon size={32} primaryColor="white" secondaryColor="black" />}
    </ToggleButtonWrapper>;
}

const ToggleButtonWrapper = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    right: -14px;
    top: 90px;
    z-index: 202;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 16px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToggleBorder = styled.div`
    width: 1px;
    height: 100%;
    background: #eee;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 190;
`;

const Wrapper = styled.div`
    width: ${p => p.visible ? "250px" : "15px"};
    min-height: 100%;
    position: relative;
`;

const InnerWrapper = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh);
    height: 100%;
    padding: 0 10px;
    background: #FAFBFC;
    padding-top: 70px;
`;

const ErrorMessageWrapper = styled.div`
    padding: 20px;
    color: black;
`;

const SpaceWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SpaceWrapperLink = styled.div`
    flex-grow: 1;
    overflow: hidden;
`;

const SpaceWrapperDropdown = styled.div`
    padding-left: 10px;
`;