import FormField from "../forms/form-field";
import React, { useEffect, useState } from "react";
import Form from "@atlaskit/form";
import { ModalFooter } from "@atlaskit/modal-dialog";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import prepareFormData from "../../utils/prepare-form-data";
import { format } from "date-fns";
import { fil } from "date-fns/locale";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";

export default function DocumentTableFormFields({ fields, onSubmit, onClose, children, values = {}, columns = [], isPending = false, isDisabledByLinks = false, root = null }) {
    const [data, setData] = useState(() => values);
    const [hidden, setHidden] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const h = [];
        fields?.filter(f => f?.field?.type === "section")?.forEach(field => {
            try {
                const options = JSON.parse(field?.options[0]);
                if (options?.hidden) {
                    h.push(field?.sectionId);
                }
            } catch (e) {
                //
            }
        });
        setHidden(h);
    }, [fields?.length]);

    const toggleSection = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [...h, sectionId]);
        }
    };

    const onSubmitHandler = React.useCallback((e, formProps) => {
        e.preventDefault();
        e.stopPropagation();
        formProps.onSubmit(e);
    });

    const isDisabled = fields?.filter(f => f?.required)?.some(f => !data[f?.name]) || isDisabledByLinks;
    const onFieldChanged = (fieldName, value) => {
        fields?.map(field => {
            let column = {};

            try {
                column = JSON.parse(field?.options);
            } catch (err) {
                console.log(err);
            }

            if (!column?.computed) return;

            const isA = column?.computed?.a === fieldName;
            const isB = column?.computed?.b === fieldName;

            if (!(isA || isB)) return;

            const aValue = isA ? value : data[column?.computed?.a];
            const bValue = isB ? value : data[column?.computed?.b];
            const op = column?.computed?.op;

            if (!aValue || !bValue || !op) return;

            let newValue;
            switch (op) {
                case "mult":
                    newValue = Number(aValue) * Number(bValue);
                    break;
                default:
                    return;
            }

            onFieldChanged(field?.name, newValue);
            setData((prev) => ({
                ...prev,
                [field?.name]: newValue
            }));
        });
    };

    return <Form onSubmit={() => {
        onSubmit(data);
    }}>
        {({ formProps }) => (
            <form {...formProps} onSubmit={e => onSubmitHandler(e, formProps)}>
                {fields
                    ?.filter(f => !toBoolean(f.hidden))
                    ?.map(field => {
                        let defaultValue = values?.[field?.id] ?? values?.[field?.name] ?? null;
                        if (field?.field?.type === "date") {
                            defaultValue = format(new Date(), "yyyy-MM-dd");
                        }

                        const inputProps = {
                            key: field.id,
                            name: field.name,
                            // label: field.label,
                            isRequired: !!field.required,
                            defaultValue: defaultValue ?? undefined,
                            options: field?.options || [],
                            isDisabled: toBoolean(field?.read_only ?? 0),
                        };

                        return <FormField
                            root={root}
                            key={field.id}
                            inputProps={inputProps}
                            value={data[field?.name]}
                            onChange={(field, d) => {
                                setData((prev) => ({
                                    ...prev,
                                    [field?.name]: d
                                }));
                                onFieldChanged(field?.name, d);
                            }}
                            onSectionToggle={sectionId => toggleSection(sectionId)}
                            sectionHidden={hidden?.includes(field?.sectionId)}
                            field={field} />;
                    })}
                {children}
                <ModalFooter>
                    <ButtonGroup>
                        <Button appearance="primary" type="submit" isDisabled={isDisabled || isPending}>
                            {t("document_table_form_fields_save")}
                        </Button>
                        <Button type="button" onClick={() => onClose()} isDisabled={isPending}>
                            {t("document_table_form_fields_cancel")}
                        </Button>
                    </ButtonGroup >
                </ModalFooter>
            </form>
        )}
    </Form>;
}