/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import styled from "styled-components";
import Form, { Field, FormFooter } from "@atlaskit/form";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import TextField from "@atlaskit/textfield";
import { useTranslation } from "react-i18next";

export default function EditRole({ role, onClose, onSubmit, isError, error, isPending = false }) {
    const { t } = useTranslation();
    
    return <>
        <ModalTransition>
            <Modal
                onClose={() => {
                    onClose();
                }}
                heading={t("admin_edit_role_heading")}
                scrollBehavior="outside"
                shouldCloseOnOverlayClick
            >
                <Wrapper>
                    <Form onSubmit={onSubmit}>
                        {({ formProps }) => (
                            <form {...formProps}>
                                <Field name="name" defaultValue={role?.name} label={t("admin_edit_role_name")} isRequired>
                                    {({ fieldProps }) => <TextField {...fieldProps} isInvalid={isError && error?.errors?.name} isDisabled={isPending} defaultValue={role?.name} />}
                                </Field>
                                <FormFooter>
                                    <ButtonGroup>
                                        <Button type="submit" appearance="primary" isLoading={isPending}>
                                            {t("admin_edit_role_save")}
                                        </Button>
                                    </ButtonGroup>
                                </FormFooter>
                            </form>
                        )}
                    </Form>
                </Wrapper>
            </Modal>
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
    margin-top: 20px;
`;