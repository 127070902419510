import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { toast } from "react-toastify";
import downloadFile from "../../utils/download-file";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function ImagePreview({ file }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const close = () => setIsOpen(false);
    const open = () => {
        setIsOpen(true);
        setData(null);
        setLoading(true);

        downloadFile(file)
            .then(d => setData(d))
            .catch(() => {
                toast.error(t("image_preview_error"));
                close();
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <span onClick={open}>
                {t("image_preview")}
            </span>

            <ModalTransition>
                {isOpen && (
                    <Modal
                        isLoading={loading}
                        actions={[
                            { text: t("image_preview_close"), onClick: close },
                        ]}
                        onClose={close}
                        isChromeless
                        width="100%"
                    >
                        <ImageViewer onClick={close}>
                            {data && <Img src={URL.createObjectURL(data)} onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                            }} />}
                        </ImageViewer>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}

const ImageViewer = styled.div`
    max-width: calc(100vw - 40px) !important;
    max-height: calc(100vh - 40px) !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;

const Img = styled.img`
max-width: calc(100vw - 100px) !important;
max-height: calc(100vw - 100px) !important;
`;