import React, { useState, useEffect, createRef, useRef } from "react";
import useDocumentsList from "../../hooks/use-documents-list";
import useDocumentFields from "../../hooks/use-document-fields";
import SearchDocumentsByType from "../documents/search-documents-by-type";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import apiClient from "../../api/api-client";
import Spinner from "../ui/spinner";
import { Link } from "react-router-dom";
import DisplayDocumentByType from "../documents/display-document-by-type";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import CommentSecondaryModal from "./comment-secondary-modal";
import AbsoluteSpinner from "../ui/absolute-spinner";
import RemoveDocumentModal from "../documents/remove-document-modal";
import Pagination from "@atlaskit/pagination";
import Lozenge from "@atlaskit/lozenge";


export default function CommentsListView({ filteredById, documentTypeId, path, orderTypeId, positionTypeId, clientTypeId }) {
    const LIMIT = 50;
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDir, setSortDir] = useState(-1);
    const [columnsOrder, setColumnsOrder] = useState(null);
    const [columnsVisible, setColumnsVisible] = useState(["name"]);
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(LIMIT);
    const [skip, setSkip] = useState(0);
    const ref = useRef();
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [loadedDocuments, setLoadedDocuments] = useState([]);
    const [more, setMore] = useState(false);
    const scrollToRefObject = (ref, offset) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
        });
    };
    const { isPending, documents, reload, pagination } = useDocumentsList(
        selectedDocument ? documentTypeId : null, page, limit, sortColumn, sortDir, filters, null, null, null, () => {
            // scrollToRefObject(ref, 200); // @todo
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        [], [], false, true);
    const { t } = useTranslation();
    const { isPending: isPendingFields, fields } = useDocumentFields(documentTypeId);
    const loadedLength = LIMIT;
    let allLength = LIMIT - loadedDocuments?.length;
    if (allLength < 0) allLength = 0;
    const [selectedSecondaryDocument, setSelectedSecondaryDocument] = useState(null);

    const fieldsMap = fields?.reduce((acc, f) => {
        acc[f?.name] = f?.id;
        return acc;
    }, {});

    useEffect(() => {
        setLoadedDocuments([]);
        if (!selectedDocument) return;
        const channelName = `comments.${documentTypeId}`;

        Echo.channel(channelName)
            .listen(".DocumentCreated", (e) => {
                if (page !== 1) {
                    setMore(true);
                    return;
                }
                if (e?.documentTypeId != documentTypeId) return;
                if (!e?.links?.map(i => i?.linked_document_id)?.includes(selectedDocument?.value)) return;
                apiClient(`workflow/document/${e?.id}`)
                    .then(r => {
                        const newDocument = {
                            id: e?.id,
                            name: findValue(fieldsMap, r?.values, "name"),
                            comment: findValue(fieldsMap, r?.values, "komentarz"),
                            date: findValue(fieldsMap, r?.values, "data"),
                            hour: findValue(fieldsMap, r?.values, "godzina"),
                            info: findValue(fieldsMap, r?.values, "info"),
                            status: findValue(fieldsMap, r?.values, "status"),
                            productStock: findValue(fieldsMap, r?.values, "productStock"),
                            productStockAfter: findValue(fieldsMap, r?.values, "productStockAfter"),
                            links: e?.links?.map(l => ({
                                id: l?.linked_document?.id,
                                document_type: {
                                    id: l?.linked_document?.document_type?.id,
                                },
                                values: l?.values,
                            })),
                        };
                        setLoadedDocuments(loaded => {
                            return [
                                newDocument,
                                ...loaded,
                            ];
                        });
                    })
                    .catch(() => { });
            });

        return () => Echo.leaveChannel(channelName);
    }, [documentTypeId, selectedDocument, page]);

    if (isPendingFields) {
        return <Spinner />;
    }

    return <Wrapper ref={ref}>
        <div>
            <SearchDocumentsByType
                value={selectedDocument}
                // isDisabled={isPending} 
                typeId={filteredById}
                selectedDocumentId={selectedDocument?.value}
                selectedDocumentTypeId={filteredById}
                isModal={false}
                onChange={e => {
                    setSelectedDocument(e);
                    setPage(1);
                    setFilters({
                        type: "AND",
                        filters: [{ id: "comments", column: null, operator: "IS", value: e, type: "document_type" }]
                    });
                }} />
        </div>
        {(documents?.data?.length + loadedDocuments?.length) === 0 && <NoCommentsView>
            {t("comments_view_no_comments")}
        </NoCommentsView>}
        {selectedDocument && pagination && pagination?.total > 0 && pagination?.per_page && <PaginationWrapper>
            <Pagination onChange={(e, p) => {
                setMore(false);
                setPage(p);
            }} selectedIndex={page - 1}
                pages={Array.from(Array(Math.ceil(pagination?.total / pagination?.per_page)).keys(), (_, i) => i + 1)} />
        </PaginationWrapper>}
        {selectedDocument && loadedDocuments?.slice(0, loadedLength)?.map(comment => <CommentWrapper key={comment.id}>
            <Comment user={comment.name} comment={comment.comment} date={comment.date} hour={comment.hour} info={comment.info} status={comment.status}
                links={comment.links} orderTypeId={orderTypeId} positionTypeId={positionTypeId} clientTypeId={clientTypeId}
                onRemoved={() => reload().then(() => setLoadedDocuments([]))}
                productStock={comment?.productStock}
                productStockAfter={comment?.productStockAfter}
                setSelectedSecondaryDocument={setSelectedSecondaryDocument} color={getColor(comment.status)} />
        </CommentWrapper>)}
        {selectedDocument && documents?.data?.slice(0, allLength)?.map(comment => <CommentWrapper key={comment.id}>
            <Comment user={comment?.values["name"]}
                info={comment?.values["informacja"]}
                status={comment?.values["status"]}
                comment={comment?.values["komentarz"]}
                productStock={comment?.values["productStock"]}
                productStockAfter={comment?.values["productStockAfter"]}
                color={getColor(comment?.values["status"])}
                positionTypeId={positionTypeId} clientTypeId={clientTypeId}
                onRemoved={() => reload().then(() => setLoadedDocuments([]))}
                date={comment?.values["data"]} hour={comment?.values["godzina"]} links={comment.links} orderTypeId={orderTypeId} setSelectedSecondaryDocument={setSelectedSecondaryDocument} />
        </CommentWrapper>)}
        {selectedDocument?.value && <AllWrapper>
            <Link to={`/${path}?document=${selectedDocument?.value}`}>
                {t("linked_all_related")}
            </Link>
        </AllWrapper>}
        {selectedSecondaryDocument && <CommentSecondaryModal resourceId={selectedSecondaryDocument} documentTypeId={orderTypeId} onClose={() => {
            setSelectedSecondaryDocument(false);
            // reload().then(() => setLoadedDocuments([]));
        }} open={true} />}
        {selectedDocument && isPending && <AbsoluteSpinner />}
        {selectedDocument && pagination && pagination?.total > 0 && pagination?.per_page && <PaginationWrapper>
            <Pagination onChange={(e, p) => {
                setMore(false);
                setPage(p);
            }} selectedIndex={page - 1}
                pages={Array.from(Array(Math.ceil(pagination?.total / pagination?.per_page)).keys(), (_, i) => i + 1)} />
        </PaginationWrapper>}
        {more && <More onClick={() => {
            setPage(1);
            setMore(false);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }}>
            Nowe komentarze
        </More>}
    </Wrapper>;
}

function findValue(fieldsMap, values, name) {
    const fieldId = fieldsMap[name];
    if (!fieldId) return null;
    return values?.find(i => i?.document_type_field_id === fieldId)?.value ?? null;
}

function Comment({ user, comment, date, status, info, hour, links = [], orderTypeId, setSelectedSecondaryDocument, onRemoved, positionTypeId, isNew = false, clientTypeId, color, productStock = 0, productStockAfter = 0 }) {
    const [orderToRemove, setOrderToRemove] = useState(null);
    const [positionToRemove, setPositionToRemove] = useState(null);
    const [highlight, setHighlight] = useState(isNew);

    useEffect(() => {
        setTimeout(() => {
            setHighlight(false);
        }, 5000);
    }, []);

    return <>
        <CommentInner color={color}>
            <CommentInnerLeft>
                <CommentUser><User user={user} links={links.filter(i => i?.document_type?.id == clientTypeId)} /> <CommentDate>{date} {hour}</CommentDate></CommentUser>
                <CommentText>{comment}</CommentText>
                {links?.filter(i => i?.document_type?.id == orderTypeId)?.map(l => (
                    <CommentLinkRow key={l?.id}>
                        <CommentLinkColumn>
                            <CommentLink>
                                <DisplayDocumentByType documentTypeId={l?.document_type?.id} id={l?.id} margin={false} height="auto" />
                            </CommentLink>
                        </CommentLinkColumn>
                        <CommentLinkColumn>
                            <ButtonGroup>
                                <Button type="button" onClick={() => setSelectedSecondaryDocument(l?.id)}>
                                    Zobacz zamówienie
                                </Button>
                                <Button type="button" appearance="subtle" onClick={() => {
                                    setOrderToRemove(l?.id);
                                }}>
                                    Usuń zamówienie
                                </Button>
                                {links?.filter(i => i?.document_type?.id === positionTypeId)?.length > 0 && <Button type="button" appearance="subtle" onClick={() => {
                                    setPositionToRemove(links?.filter(i => i?.document_type?.id === positionTypeId)[0]?.id);
                                }}>
                                    Usuń pozycję
                                </Button>}
                            </ButtonGroup>
                        </CommentLinkColumn>
                        <CommentLinkGrow />
                        {productStock > 0 && <CommentLinkColumn>
                            <CommentLink>
                                Stan produktu (przed/po): <strong>{productStock}/{productStockAfter}</strong>
                            </CommentLink>
                        </CommentLinkColumn>}
                    </CommentLinkRow>
                ))}
                {/* {status == "brak" && <CommentLinkRow >
                    <CommentLinkColumn>
                        <ButtonGroup>
                            <Button type="button" onClick={() => {

                            }}>Dodaj rezerwację</Button>
                        </ButtonGroup>
                    </CommentLinkColumn>
                    <CommentLinkGrow />
                </CommentLinkRow>}
                {status == "zablokowany" && <CommentLinkRow >
                    <CommentLinkColumn>
                        <ButtonGroup>
                            <Button type="button" onClick={() => {

                            }}>Zmień blokadę</Button>
                        </ButtonGroup>
                    </CommentLinkColumn>
                    <CommentLinkGrow />
                </CommentLinkRow>} */}
            </CommentInnerLeft>
            <CommentInnerRight>
                {status && <Lozenge appearance={getAppearance(status)}>{getInfo(status)}</Lozenge>}
            </CommentInnerRight>
        </CommentInner>

        {orderToRemove && <RemoveDocumentModal document={{ id: orderToRemove }} onRemoved={() => {
            setOrderToRemove(null);
            onRemoved && onRemoved();
        }} onClose={() => setOrderToRemove(null)} />}
        {positionToRemove && <RemoveDocumentModal document={{ id: positionToRemove }} onRemoved={() => {
            setPositionToRemove(null);
            onRemoved && onRemoved();
        }} onClose={() => setPositionToRemove(null)} />}
    </>;
}

function User({ links, user }) {
    if (links.length > 0) {
        const l = links[0];
        const name = l?.values?.find(i => i?.name === "name")?.value ?? "-";
        return <DocumentLink link={l} margin={false} height="auto" />;
    }

    return null;
}

const getAppearance = (status) => {
    switch (status) {
        case "zamowienie":
            return "success";
        case "zablokowany":
            return "new";
        case "brak":
            return "removed";
        case "rezerwacja":
            return "moved";
        default:
            return "inprogress";
    }
};

const getInfo = (status) => {
    switch (status) {
        case "zamowienie":
            return "ZAMÓWIENIE";
        case "zablokowany":
            return "ZABLOKOWANY";
        case "brak":
            return "BRAK PRODUKTU";
        case "rezerwacja":
            return "REZERWACJA";
        default:
            return "";
    }
};

const getColor = (status) => {
    switch (status) {
        case "zamowienie":
            return "rgba(227, 252, 239, 0.6)";
        case "zablokowany":
            return "rgba(234, 230, 255, 0.6)";
        case "brak":
            return "rgba(255, 235, 230, 0.6)";
        case "rezerwacja":
            return "rgba(255, 240, 179, 0.6)";
        default:
            return "inprogress";
    }
};

const Wrapper = styled.div``;

const NoCommentsView = styled.div`
    margin: 20px auto;
    padding: 20px;
    border-radius: 5px;
    background: #f0f0f0;
`;

const CommentWrapper = styled.div`
    margin: 20px auto;
`;

const CommentInner = styled.div`
    padding: 20px;
    border-radius: 5px;
    background: ${props => props.color};
    box-shadow: 0px 2px 10px 0px #eee;
    transition: background-color 2s ease;
    border: 1px solid #eee;
    display: flex;
`;

const CommentInnerLeft = styled.div`
    flex-grow: 1;
`;

const CommentInnerRight = styled.div`
    padding-left: 30px;
`;

const CommentUser = styled.div`
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-items: center;
`;

const CommentText = styled.p`
    font-size: 16px;
    line-height: 1.1;
    font-weight: 600;
    margin: 20px auto 30px;
`;

const CommentDate = styled.h5`
    font-size: 12px;
    font-weight: normal;
    color: #aaa;
    display: inline-block;
    margin-left: 5px;
    margin-top: 0;
    padding-top: 12px;
`;

const CommentLink = styled.div`
display: inline-block;
margin-bottom: 5px;
margin-right: 10px;
padding: 0px 5px 0;
background: white;
border: 1px solid #ddd;
border-radius: 5px;
`;

const AllWrapper = styled.div`
    padding: 10px;
`;

const CommentLinkRow = styled.div`
    display: flex;
`;

const CommentLinkColumn = styled.div`
`;

const CommentLinkGrow = styled.div`
    flex-grow: 1;
`;

const PaginationWrapper = styled.div`
    padding: 20px;
`;

const More = styled.div`
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    width: 200px;
    left: calc(50% - 100px);
    background: blue;
    color: white;
    text-align: center;
    font-weight: bold;
`;

function DocumentLink({ link, margin = true, height = "30px" }) {
    const nameField = link?.values?.find(i => i.name === "name");
    const name = nameField?.value ?? null;
    if (!name) return null;
    const path = `live/klienci/klient/${link?.id}`;
    return <DocumentLinkWrapper margin={margin}>
        <Link to={`/${path}`}>
            <DocumentLinkRow height={height}>
                {name}
            </DocumentLinkRow>
        </Link>
    </DocumentLinkWrapper>;
}


const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-bottom: ${props => props.margin ? "15px" : "0px"};
    margin-top: 5px;
`;

const DocumentLinkRow = styled.div`
    height: ${props => props.height};
    display: flex;
    font-weight: 600;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
    margin-right: 5px;
`;

const DocumentFrame = styled.div`
    display: inline-block;
    width: auto;
    height: ${props => props.height};
    padding: 2px 5px;
    margin: 0px 0;
    overflow: hidden;
    position: relative;
`;