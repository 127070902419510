import React from "react";
import useDocumentWorkflows from "../../hooks/use-document-workflows";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function DocumentWorkflowsDropdown({ document, onRemove, onSuccess, canRemove = true }) {
    const { t } = useTranslation();
    const { data: workflows, callback } = useDocumentWorkflows(document);
    const documentTypeWorkflows = document?.document_type?.workflows?.filter(i => parseInt(i?.workflow_type_id) === 2) ?? [];

    const runDocumentTypeWorkflow = workflowId => {
        apiClient(`workflow/${workflowId}/document/${document?.id}`, { method: "POST" })
            .then(onSuccess)
            .catch(e => toast.error(e.message));
    };

    const count = workflows?.length
        + documentTypeWorkflows?.length
        + (canRemove ? 1 : 0 );

    if (!count) return null;

    return <>
        <DropdownMenu position="bottom right" triggerType="button">
            <DropdownItemGroup>
                {canRemove && <DropdownItem onClick={() => onRemove()}>
                    {t("document_workflow_remove")}
                </DropdownItem>}
            </DropdownItemGroup>
            <DropdownItemGroup>
                {document?.document_type?.print_patterns?.map(pdf => (
                    <DropdownItem key={pdf.id} onClick={() => {

                    }}>
                        {pdf?.name}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
            <DropdownItemGroup>
                {workflows.map(workflow => (
                    <DropdownItem key={workflow.id} onClick={() => callback(workflow).then(onSuccess)}>
                        {workflow.label}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
            {documentTypeWorkflows.length > 0 && <DropdownItemGroup>
                {documentTypeWorkflows.map(workflow => (
                    <DropdownItem key={workflow.id} onClick={() => runDocumentTypeWorkflow(workflow?.id)}>
                        {workflow.name}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>}
        </DropdownMenu>
    </>;
}

export default DocumentWorkflowsDropdown;