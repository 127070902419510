import React, { useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../ui/spinner";
import InlineMessage from "@atlaskit/inline-message";
import styled from "styled-components";
import DocumentsList from "./documents-list";
import useDocumentsList from "../../hooks/use-documents-list";
import useDocumentStatuses from "../../hooks/use-document-statuses";
import SectionMessage from "@atlaskit/section-message";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types";
import { useTranslation } from "react-i18next";
import useSpace from "../../hooks/use-space";
import toBoolean from "../../utils/to-boolean";
import Filters from "../filters/filters";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { toast } from "react-toastify";
import { ButtonGroup } from "@atlaskit/button";
import Button from "@atlaskit/button/custom-theme-button";
import PageHeader from "@atlaskit/page-header";
import { Helmet } from "react-helmet";
import documentName from "../../utils/document-name";

function Documents({ documentTypeId, document, columns = [], document_links = [], slug, editMode = false, linkTypeId = 3, columnsWidths = {}, defaultColumnWidth = 200, colorField, filtersEnabled = false, showColumnMenuTool = false, currentSection, createButton = false, onReload, noColumnOrder = false }) {
    const { t } = useTranslation();
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDir, setSortDir] = useState(1);
    const [filters, setFilters] = useState(null);
    const [filtersOpen, setFiltersOpen] = useState(null);
    const { statuses } = useDocumentStatuses(documentTypeId);
    const { data: availableDocumentTypes, isLoaded: isLoadedDocumentTypes } = useAvailableDocumentTypes(documentTypeId);
    const [columnsOrder, setColumnsOrder] = useState([
        "name", ...columns?.filter(i => !toBoolean(i.hidden))
            ?.filter(i => {
                if (i.type == "relation") {
                    return availableDocumentTypes?.map(i => i?.value)?.includes(i?.document_type_id);
                }
                return true;
            })
            ?.map(i => {
                if (i.type == "relation") {
                    return `document.${i?.document_type_id}`;
                }
                return i.name;
            })
    ]);
    const [columnsVisible, setColumnsVisible] = useState([
        "name", ...columns?.filter(i => !toBoolean(i.hidden))?.map(i => i.name)
    ]);
    const [addFormOpen, setAddFormOpen] = useState(false);

    const allFilters = useMemo(() => (
        {
            ...filters,
            filters: [
                ...(filters?.filters ?? []),
                {
                    id: 0,
                    column: document?.document_type_id,
                    operator: "IS",
                    type: "document_type",
                    value: {
                        data: document,
                        value: document.id,
                        label: "Document",
                    }
                }
            ]
        }
    ), [filters]);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    const { isPending, fields, documents, savedColumns, isLoadedFields, reload, exportList } = useDocumentsList(documentTypeId, page, limit, sortColumn, sortDir, allFilters);
    const { space, isPending: isPendingSpace, notFound } = useSpace(slug);
    const spaceConfig = space?.config;
    const exportEnabled = !spaceConfig?.exportDisabled;
    const sortHandle = useCallback((value) => {
        setSortColumn(value?.name ?? "id");
        setSortDir(parseInt(value?.dir));
    }, []);
    const filteredFields = fields?.filter(f => !["list"].includes(f?.field?.type)).filter(i => i?.field?.type !== "section");
    const onColumnVisibleChange = useCallback(({ column, visible }) => {
        const name = column?.name;

        if (visible) {
            setColumnsVisible(v => ([
                ...v,
                name
            ]));
        } else {
            setColumnsVisible(v => v?.filter(i => i !== name));
        }
    }, []);

    const updatePage = (l, s) => {
        const p = 1 + parseInt(s / l);
        setSkip(s);
        setLimit(l);
        setPage(p);
    };

    if (isPendingSpace) {
        return <Spinner />;
    }

    const footerRows = [
        {
            render: columns.filter(c => c.summary).map(c => ({
                name: c.name,
                value: 0
            })).reduce((acc, c) => {
                const col = columns?.find(cl => cl?.name == c?.name);
                console.log(col, col?.align);
                // eslint-disable-next-line react/display-name
                acc[c.name] = ({ summary }) => <FooterRow align={col?.align ?? "center"}>{summary[c.name] ?? "-"}</FooterRow>;
                return acc;
            }, {})
        }
    ];

    const summaryReducer = {
        initialValue: columns.filter(c => c.summary).map(c => ({
            name: c.name,
            value: 0
        })).reduce((acc, c) => {
            acc[c.name] = 0;
            return acc;
        }, {}),
        reducer: (accumulator, item) => {
            columns.filter(c => c.summary).map(c => {
                const docId = item.id;
                const doc = documents?.data?.find(i => i?.id == docId);

                if (!doc) return;

                accumulator[c.name] += parseFloat(doc?.values[c.name] ?? 0);
            });

            return accumulator;
        },
    };

    const download = (format) => {
        exportList(format)
            .then(data => {
                let element = window.document.createElement("a");
                element.setAttribute("href", URL.createObjectURL(data));
                element.setAttribute("download", `${slug}.${format}`);
                element.style.display = "none";
                window.document.body.appendChild(element);
                element.click();
                window.document.body.removeChild(element);
                setTimeout(() => {
                    URL.revokeObjectURL(data);
                    element.remove();
                }, 100);
            })
            .catch((e) => {
                console.log(e);
                toast.error(t("space_export_error"));
            });
    };


    const reloadAll = () => {
        reload();
        onReload && onReload();
    };

    const columnsFlex = columns?.reduce((acc, c) => {
        acc[c?.name] = c?.flex ?? undefined;
        return acc;
    }, {});


    return <>
        {!documentTypeId && <DocumentTypeError />}
        {documentTypeId && <DocumentsListWrapper>
            {filtersEnabled && <>
                {space && space.name && <Helmet>
                    <title>{`${space?.name} - ${documentName(document)} - ERPspace`}</title>
                </Helmet>}
                <PageHeader
                    id="list-page-header"
                    actions={
                        <ButtonGroup>
                            <HeaderButtonWrapper>
                                <Button onClick={() => setFiltersOpen(value => !value)}>
                                    <span>{t("documents_filters")}</span>
                                </Button>
                            </HeaderButtonWrapper>
                            {exportEnabled && <HeaderButtonWrapper>
                                <DropdownMenu position="bottom right" triggerType="button" trigger="Export">
                                    <DropdownItemGroup>
                                        <DropdownItem onClick={() => download("csv")}>
                                            CSV
                                        </DropdownItem>
                                        <DropdownItem onClick={() => download("xlsx")}>
                                            XLSX
                                        </DropdownItem>
                                    </DropdownItemGroup>
                                </DropdownMenu>
                            </HeaderButtonWrapper>}
                            <HeaderButtonWrapper>
                                <Button appearance="primary" type="submit" onClick={e => {
                                    e.preventDefault();
                                    setAddFormOpen(true);
                                }}>
                                    {t("new_button")}
                                </Button>
                            </HeaderButtonWrapper>
                        </ButtonGroup>
                    }>
                    {listName(document, currentSection)}
                </PageHeader>
            </>}
            {filtersEnabled && filtersOpen && <FiltersArea>
                <Filters documentTypeId={documentTypeId}
                    initialFilters={filters?.filters}
                    initialLinks={filters?.links}
                    initialType={filters?.type}
                    onAccept={e => {
                        setPage(1);
                        setFilters(e);
                        setFiltersOpen(false);
                    }}
                    onCancel={() => setFiltersOpen(false)}
                    onHide={() => setFiltersOpen(false)}
                    statuses={statuses}
                    sortColumn={sortColumn}
                    sortDir={sortDir}
                    columnsVisible={columnsVisible}
                    columns={[
                        { id: "name", label: fields?.find(i => i?.name === "name")?.label ?? t("column_name"), name: "name", type: "text" },
                        { id: "users", label: t("column_users"), name: "users", type: "users" },
                        ...columns,
                        ...availableDocumentTypes?.map(dt => ({
                            id: dt.value, label: dt.label, name: dt.value, type: "document_type",
                        })) || [],
                    ]} />
            </FiltersArea>}
            <DocumentsList
                isPending={isPending || isPendingSpace}
                fields={filteredFields} documents={documents}
                editMode={editMode}
                actionsColumn={editMode}
                createButton={createButton}
                colorField={colorField}
                addFormOpen={addFormOpen}
                setAddFormOpen={setAddFormOpen}
                height={500}
                linkTypeId={linkTypeId}
                summaryReducer={summaryReducer}
                footerRows={footerRows}
                contextDocument={document}
                document_links={document_links}
                columnsFlex={columnsFlex}
                onSkipChange={s => {
                    updatePage(limit, parseInt(s));
                }}
                onLimitChange={l => {
                    updatePage(parseInt(l), skip);
                }}
                limit={limit}
                skip={skip}
                availableDocumentTypes={availableDocumentTypes}
                savedColumns={savedColumns}
                sortHandle={sortHandle}
                sortColumn={sortColumn}
                sortDir={sortDir}
                columnsOrder={noColumnOrder ? undefined : columnsOrder}
                onColumnOrderChange={setColumnsOrder}
                columnVisible={columnsVisible}
                onColumnVisibleChange={onColumnVisibleChange}
                space={space}
                showColumnMenuTool={showColumnMenuTool}
                columnsWidths={columnsWidths}
                defaultColumnWidth={defaultColumnWidth}
                reload={reloadAll}
                setColumnsOrder={setColumnsOrder} columns={columns} />
        </DocumentsListWrapper>}
    </>;

    function DocumentTypeError() {
        return <SectionMessage
            appearance="error"
            testId="alert"
            title="Error"
        >
            {t("documents_no_document_type")}
        </SectionMessage>;
    }
}

export default Documents;

const ErrorMessageWrapper = styled.div`
    padding: 20px;
    color: black;
`;

const DocumentsListWrapper = styled.div`
max-width: 100%;
overflow-x: auto;
margin-top: 10px;
`;


const FiltersArea = styled.div`
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    background: #FAFBFC;
`;

const HeaderButtonWrapper = styled.div`
    margin-left: 10px;
`;

function listName(document, currentSection) {
    const linkable = document?.linkable?.find(l => decodeURI(currentSection) == l?.name);
    return linkable?.label;
}

const FooterRow = styled.div`
    text-align: ${props => props.align ?? "center"};
    display: block;
    width: 100%;
`;