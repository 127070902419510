import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FileIcon from "@atlaskit/icon/glyph/file";
import ProgressBar, { SuccessProgressBar } from "@atlaskit/progress-bar";
import { toast } from "react-toastify";
import ImagePreview from "../files/image-preview";
import PdfPreview from "../files/pdf-preview";
import Comment, {
    CommentAction,
    CommentTime,
} from "@atlaskit/comment";
import MediaServicesScaleLargeIcon from "@atlaskit/icon/glyph/media-services/scale-large";
import downloadFile from "../../utils/download-file";
import { API_URL } from "../../api/api-client";
import DeleteFileModal from "./delete-file-modal";
import { useTranslation } from "react-i18next";

function FileItem({ documentId, file, onSuccess, onError, onRemoved, editMode = false }) {
    const { t } = useTranslation();
    const [ isDownloading, setIsDownloading ] = useState(false);
    useEffect(() => {
        if (file.status !== "uploading") return;
        const token = window.localStorage.getItem("auth.token");
        const data = new FormData();
        data.append("file", file.file);
        window.fetch(`${API_URL}/document/${documentId}/file`, {
            method: "POST",
            body: data,
            headers: {
                "Authorization": token ? `Bearer ${JSON.parse(token)}` : undefined,
            },
        })
            .then(response => {
                if (response.ok) {
                    onSuccess();
                    toast.success(t("attachments_success"));
                } else {
                    onError();
                    toast.error(t("attachments_error"));
                }
            })
            .catch(error => {
                onError();
                toast.error(t("attachments_error"));
            });
    }, [ file ]);

    const download = e => {
        e.preventDefault();
        setIsDownloading(true);
        downloadFile(file)
            .then(data => {
                let element = document.createElement("a");
                element.setAttribute("href", URL.createObjectURL(data));
                element.setAttribute("download", file.name);
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                setTimeout(() => {
                    URL.revokeObjectURL(data);
                    element.remove();
                }, 100);
            })
            .catch(() => toast.error(t("attachments_download_error")))
            .finally(() => setIsDownloading(false));
    };

    const actions = [];

    if (file.created_at) actions.push(<CommentAction key="download" onClick={e => download(e)} isDisabled={isDownloading}>
        {t("attachments_download")}
    </CommentAction>);
    if (file.created_at && isImage(file)) actions.push(<CommentAction><ImagePreview file={file} /></CommentAction>);
    if (file.created_at && isPdf(file)) actions.push(<CommentAction><PdfPreview file={file} /></CommentAction>);
    if (file.created_at && file.id) actions.push(<CommentAction><DeleteFileModal file={file} onRemoved={onRemoved} /></CommentAction>);

    return <Comment
        avatar={isImage(file) ? <MediaServicesScaleLargeIcon /> : <FileIcon />}
        time={<CommentTime>{file.created_at && (new Date(file.created_at)).toLocaleString()}</CommentTime>}
        content={
            <>
                <div>{file.name}</div>
                <FileProgress>
                    {file.status === "uploading" && <ProgressBar isIndeterminate />}
                    {file.status === "uploaded" && <SuccessProgressBar value={1} />}
                </FileProgress>
            </>
        }
        actions={actions}
    />;
}

export default FileItem;

const isImage = file => {
    return /(png|jpe?g|gif)$/i.test(file.name);
};

const isPdf = file => {
    return /(pdf)$/i.test(file.name);
};

const FileProgress = styled.div`
    margin-top: 3px;
  `;