import i18next from "i18next";
import React, { createContext, useCallback, useMemo, useEffect, useContext, useState } from "react";
import apiClient from "../api/api-client";
import FullPageSpinner from "../components/ui/full-page-spinner";
import useAsync from "../hooks/use-async";

export const AuthContext = createContext();

export default function AuthProvider(props) {
    const { run, data: user, isPending, isIdle, setStatus } = useAsync();
    const [ globalStatus, setGlobalStatus ] = useState(null);
    const [ userStatus, setUserStatus ] = useState(null);

    const reload = () => {
        run(apiClient("user"))
            .then(response => {
                const locale = response?.locale?.slice(0, 2) ?? "en";
                i18next.changeLanguage(locale);
                setGlobalStatus(response?.globalStatus);
                setUserStatus(response?.userStatus);
            })
            .catch(() => {
                window.localStorage.removeItem("auth.token");
            });
    };

    useEffect(() => {
        const token = window.localStorage.getItem("auth.token");
        if (!token) {
            setStatus("resolved");
            return;
        }

        setStatus("pending");
        reload();
    }, []);

    const logout = useCallback(() => {
        window.localStorage.removeItem("auth.token");
        window.location.href = "/login";
    });

    const value = useMemo(() => ({ logout, user, reload, setGlobalStatus, setUserStatus, globalStatus, userStatus }), [user, globalStatus, userStatus]);

    if (isPending || isIdle) {
        return <FullPageSpinner />;
    }

    return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
    const auth = useContext(AuthContext);

    if (auth === undefined) {
        throw new Error("useAuth must be used inside AuthProvider");
    }

    return auth;
}