/* eslint-disable indent */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { ErrorMessage, Field, HelperMessage } from "@atlaskit/form";
import { Radio } from "@atlaskit/radio";
import Lozenge from "@atlaskit/lozenge";
import useOrder from "../../hooks/use-order";
import Spinner from "../../components/ui/spinner";
import { toast } from "react-toastify";

export default function Payment({ uuid }) {
    const [errors, setErrors] = useState({});
    const [shipping, setShipping] = useState(null);
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [open, setOpen] = useState(false);
    const [postcode, setPostcode] = useState("");
    const [house, setHouse] = useState("");
    const { order, isPending, pay } = useOrder(uuid);
    const delivery = order?.delivery?.find(i => i?.id == shipping);
    const total = parseFloat(order?.package?.orders?.reduce((acc, order) => {
        if (order?.status !== "W toku" && order?.status != null && order?.status !== "") return acc;

        return acc + order?.positions?.reduce((acc, pos) => {
            const price = parseFloat(pos.sale) > 0 ? parseFloat(pos.sale) : parseFloat(pos.price);
            const count = parseFloat(pos?.count ?? 1);

            return acc + count * (price ?? 0);
        }, 0) ?? 0;
    }, 0)) + parseFloat((delivery?.price ?? 0));

    const deliveryPaid = order?.package?.orders?.map(o => parseFloat(o?.payment?.delivery_paid ?? 0))?.reduce((acc, i) => {
        console.log(i);
        acc = acc + i;
        return acc;
    });

    React.useEffect(() => {
        if (isPending) return;
        if (!order) return;
        if (shipping) return;

        setStreet(order?.package?.address?.street ?? "");
        setCity(order?.package?.address?.city ?? "");
        setPostcode(order?.package?.address?.postcode ?? "");
        setShipping(order?.package?.orders?.map(o => o?.payment?.deliverer?.id)?.filter(i => i != null)?.[0]);
    }, [order, isPending]);

    const onSubmit = () => {
        setErrors({});
        pay({
            orderId: uuid,
            delivererId: shipping,
            formatId: delivery?.format_id,
            street,
            city,
            postcode,
            open,
            phone,
        })
            .then(res => {
                if (res?.redirect) {
                    window.location.href = res.redirect;
                    return;
                }

                if (res?.reason) {
                    toast.error(res.reason);
                }

                if (res?.errors) {
                    setErrors(res?.errors);
                }
            })
            .catch(err => {
                if (err?.reason) {
                    switch (err.reason) {
                        case "MULTIPLY_PAID_TRANSACTION":
                            toast.error("Zamówienie zostało już opłacone.");
                            return;
                    }
                }

                if (err?.errors) {
                    setErrors(err?.errors);
                }
            });
    };

    const isDisabled = !shipping || street?.length === 0 || city?.length === 0 || postcode?.length === 0 || phone?.length === 0 || isPending;

    return <>
        <Helmet>
            <title>{order?.name}</title>
        </Helmet>
        <Wrapper>
            <Left>
                <Header>Zamówienie nr {order?.name}</Header>
                <Summary>
                    <h3 style={{ marginBottom: "25px" }}>Podsumowanie</h3>

                    {order?.package?.orders?.map((order, i) => (
                        <>
                            <Flex>
                                <Main>
                                    <h4>Zamówienie #{i + 1}</h4>
                                </Main>
                                <div>
                                    {(order?.status == "W toku" || order?.status == null || order?.status === "") ? <Lozenge appearance="inprogress">W toku</Lozenge> : null}
                                    {order?.status == "Anulowane" ? <Lozenge appearance="removed">Anulowane</Lozenge> : null}
                                    {(order?.status != "W toku" && order?.status != null && order?.status !== "") && order?.status != "Anulowane" ? <Lozenge appearance="success">Zapłacone</Lozenge> : null}
                                </div>
                            </Flex>
                            <SummaryList style={{ opacity: "0.5" }}>
                                {order?.positions?.map(position => (
                                    <FlexListItem key={position?.name}>
                                        <Main>{position?.count} x {position?.name}</Main>
                                        <RightAlign>{parseFloat(position.sale) > 0 ? parseFloat(parseFloat(position?.count) * parseFloat(position.sale)) : parseFloat(parseFloat(position?.count) * parseFloat(position.price))}zł</RightAlign>
                                    </FlexListItem>
                                ))}
                            </SummaryList>
                        </>
                    ))}
                    <SummaryList>
                        <FlexListItem>
                            <Main>Koszty wysyłki</Main>
                            <RightAlign>{delivery ? `${parseFloat(delivery?.price).toFixed(2)}zł` : "-"}</RightAlign>
                        </FlexListItem>
                    </SummaryList>
                    <SummaryPrice>
                        <Flex>
                            <Main>
                                <strong>Razem</strong>
                            </Main>
                            <div>
                                <SummaryPriceHighlight>
                                    <span>{!isNaN(total) ? parseFloat(total).toFixed(2) : "-"}</span> zł
                                </SummaryPriceHighlight>
                            </div>
                        </Flex>
                    </SummaryPrice>
                </Summary>


                <Section>
                    <SectionHeader>Otwarta paczka</SectionHeader>
                    <Checkbox size="large" isChecked={open} onChange={e => setOpen(i => !i)} label={<div>
                        Po zapłacie otwarta paczka nie zostanie wysłana.
                        Będziesz mogła/mógł dołączyć do paczki kolejne zamówienie.
                    </div>} />

                    {open && <OpenPackageSection>
                        Paczka zostanie zamknięta w ciągu 2 dni roboczych.
                    </OpenPackageSection>}
                </Section>
                <Section>
                    <SectionHeader>Dostawca</SectionHeader>
                    {
                        order?.delivery?.map(d => (
                            <Radio
                                key={d.name}
                                value={d.id}
                                label={`${d.name} - ${parseFloat(d?.price).toFixed(2)}zł`}
                                name="shipping"
                                isChecked={shipping == d.id}
                                onChange={e => setShipping(d.id)}
                            />
                        ))
                    }

                    {deliveryPaid > 0 && <OpenPackageSection>
                        Zapłacono już za dostawę: <strong>{parseFloat(deliveryPaid).toFixed(2)}zł</strong>
                    </OpenPackageSection>}
                </Section>
                <Section>
                    <SectionHeader>Dane adresowe</SectionHeader>
                    <FlexLg>
                        <Main>
                            <Field label="Ulica i nr domu" name="street" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={street} onChange={e => setStreet(e.target.value)} />
                                )}
                            </Field>
                            {errors?.street && <ErrorMessage>{errors?.street[0]}</ErrorMessage>}
                        </Main>
                        <Block15Lg />
                        <div>
                            <Field label="Nr telefonu" name="phone" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={phone} onChange={e => setPhone(e.target.value)} />
                                )}
                            </Field>
                            {errors?.phone && <ErrorMessage>{errors?.phone[0]}</ErrorMessage>}
                        </div>
                    </FlexLg>
                    <FlexLg>
                        <div>
                            <Field label="Kod pocztowy" name="postal" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={postcode} onChange={e => setPostcode(e.target.value)} />
                                )}
                            </Field>
                            {errors?.postcode && <ErrorMessage>{errors?.postcode[0]}</ErrorMessage>}
                        </div>
                        <Block15Lg />
                        <Main>
                            <Field label="Miejscowość" name="city" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} value={city} onChange={e => setCity(e.target.value)} />
                                )}
                            </Field>
                            {errors?.city && <ErrorMessage>{errors?.city[0]}</ErrorMessage>}
                        </Main>
                    </FlexLg>
                </Section>
                <div style={{ marginTop: "40px" }}>
                    <Button appearance="primary" shouldFitContainer height={50} isDisabled={isDisabled} onClick={onSubmit}>
                        Zapłać {!isNaN(total) ? parseFloat(total).toFixed(2) : "-"} zł
                    </Button>
                </div>
            </Left>
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
padding: 20px;
@media (min-width: 600px) {
    padding: 40px;
    display: flex;
    max-width: 600px;
    margin: 0px auto;
}
`;

const Left = styled.div`
flex: 3;
`;

const Right = styled.div`
flex: 2;

margin-top: 40px;
@media (min-width: 900px) {
    padding: 20px;
    padding-left: 100px;
}
`;

const Header = styled.h1`
font-size: 25px;
margin: 0;
padding: 0;
`;


const Section = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid #eee;
`;

const SectionHeader = styled.h3`
font-size: 16px;
margin-bottom: 10px;
`;

const Summary = styled.div`
    background: #F5F5F7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 20px;
`;

const SummaryList = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
`;

const SummaryPrice = styled.div`

`;

const Flex = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexListItem = styled.div`
display: flex;
align-items: flex-end;
margin: 10px 0;
`;

const FlexLg = styled.div`
@media (min-width: 900px) {
    display: flex;
    align-items: flex-end;
}
`;

const Main = styled.div`
    flex: 1;
`;

const SummaryPriceHighlight = styled.h2`
`;

const OpenPackageSection = styled.div`
    background: #F5F5F7;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0 0;
`;

const Block15Lg = styled.div`
@media (min-width: 900px) {
    width: 15px;
    height: 100%;
}
`;

const RightAlign = styled.div`
    text-align: right;
`;