import React from "react";
import LoginForm from "../../components/auth/login-form";
import AuthLayout from "../../layouts/auth-layout";
import { useLocation } from "react-router-dom";

function Login() {
    const query = useQuery();
    const redirect = query.get("redirect");

    return <AuthLayout>
        <LoginForm redirect={redirect} />
    </AuthLayout>;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default Login;