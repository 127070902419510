import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useDocumentLinkTypes from "../../hooks/use-document-link-types";
import Spinner from "../ui/spinner";
import Select from "@atlaskit/select";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types";
import Button from "@atlaskit/button";
import SearchDocumentsByType from "./search-documents-by-type";
import SearchCompany from "./search-company";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import useGlobalConfig from "../../hooks/use-global-config";
import { useTranslation } from "react-i18next";

function LinkDocumentForm({ document, onCancel, onSuccess }) {
    const { t } = useTranslation();
    const { data, isPending } = useDocumentLinkTypes();
    const [ type, setType ] = useState(null);
    const [ documentType, setDocumentType ] = useState(null);
    const [ selectedDocument, setSelectedDocument ] = useState(null);
    const { data: availableDocumentTypes, isPending: isAvailableDocumentTypesLoading } = useAvailableDocumentTypes(document?.document_type_id);
    const { run, isPending: isSaving } = useAsync();
    const types = data?.map(i => ({ value: i.id, label: i.name })) || [];
    const { config: typesMapConfig, isPending: isConfigPending } = useGlobalConfig("types_map");

    useEffect(() => {
        if (!types) return;
        if (type) return;
        setType(types?.find(i => i.value === typesMapConfig?.relatedId));
    }, [ types ]);

    if (isPending || isConfigPending) {
        return <Spinner />;
    }

    const handleSubmit = () => {
        if (!selectedDocument) return;
        
        run(apiClient(`document/${document.id}/link/${selectedDocument.value}/type/${type?.value}`, {
            method: "POST"
        }))
            .then(() => {
                toast.info(t("linked_form_success"));
                onSuccess();
            })
            .catch(error => toast.error(`${t("linked_form_error")}: ${error.message}`));
    };

    const selectedDocumentType = availableDocumentTypes?.find(i => i.value === documentType?.value);
    const isModal = selectedDocumentType?.isModal;

    return <AddForm onSubmit={handleSubmit}>
        <AddFormRow>
            <Select 
                options={types} 
                isDisabled={isSaving}
                isLoading={!types}
                defaultValue={types?.find(i => i.value === typesMapConfig?.relatedId)}
                placeholder={t("linked_form_type")} onChange={e => {
                    setSelectedDocument(null);
                    setDocumentType(null);
                    setType(e);
                }} />
        </AddFormRow>
        {availableDocumentTypes && <AddFormRow>
            <Select options={availableDocumentTypes} 
                value={documentType}
                isDisabled={isSaving}
                autoFocus
                placeholder={t("linked_form_document_type")} onChange={e => {
                    setSelectedDocument(null);
                    setDocumentType(e);
                }} isLoading={isAvailableDocumentTypesLoading} />
        </AddFormRow>}
        {documentType && <AddFormRow>
            <SearchDocumentsByType 
                value={selectedDocument}
                selectedDocumentId={selectedDocument?.value}
                selectedDocumentTypeId={documentType?.value}
                direction={documentType?.attributes?.dir ?? -1}
                isDisabled={isSaving} 
                typeId={documentType?.value} 
                isModal={isModal}
                onChange={e => setSelectedDocument(e)} />
        </AddFormRow>}
        <ButtonsWrapper>
            <Spacer />
            <ButtonWrapper>
                <Button appearance="default" onClick={onCancel} isDisabled={isSaving}>
                    {t("linked_form_cancel")}
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button appearance="primary" isDisabled={!selectedDocument || !type} isLoading={isSaving}  onClick={handleSubmit}>
                    {t("linked_form_button")}
                </Button>
            </ButtonWrapper>
        </ButtonsWrapper>
    </AddForm>;
}

export default LinkDocumentForm;

const AddForm = styled.form`
display: block;
width: 100%;
`;

const AddFormRow = styled.div`
width: 100%;
margin-bottom: 10px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;