import React from "react";
import styled from "styled-components";
import Spinner from "../ui/spinner";
import useWidget from "../../hooks/use-widget";
import TextWidget from "./text-widget";
import ChartWidget from "./chart-widget";
import TableWidget from "./table-widget";
import IconWidget from "./icon-widget";
import DashboardDocuments from "../dashboard/dashboard-documents";
import DashboardNotifications from "../dashboard/dashboard-notifications";
import { useTranslation } from "react-i18next";

function Widget({ name, color = "#2684ff", module = null, documentId = null }) {
    const { data, isPending, isError } = useWidget(name, documentId);
    const { t } = useTranslation();

    if (name === "ERROR") {
        return <Wrapper color={color}>
            <h4>{t("widget_error_heading")}</h4>
            <p>{t("widget_error_p")}</p>
        </Wrapper>;
    }

    if (name === "ASSIGNED") {
        return <Wrapper color={color}>
            <h4>{t("dashboard_assigned")}</h4>
            <DashboardDocuments module={module} />
        </Wrapper>;
    }

    if (name === "NOTIFICATIONS") {
        return <Wrapper color={color}>
            <h4>{t("dashboard_notifications")}</h4>
            <DashboardNotifications module={module}  />
        </Wrapper>;
    }

    return <Wrapper color={color}>
        {
            isPending || isError
                ? <Spinner />
                : <WidgetInner data={data} />
        }
    </Wrapper>;
}

function WidgetInner({ data }) {
    return <>
        {Array.isArray(data) && data?.map((element, index) => (
            <WidgetInnerWrapper 
                key={index} 
                marginBottom={element?.marginBottom ?? "10px"}
                align={element?.align ?? "left"}
            >
                <Element type={element?.type} data={element} /> 
            </WidgetInnerWrapper>
        ))}
    </>;
}

function Element({ type, data }) {
    switch (type) {
    case "line":
    case "bar":
    case "radar":
    case "doughnut":
    case "polarArea":
    case "bubble":
    case "scatter":
        return <ChartWidget type={type} {...data} />;
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
    case "p":
    case "a":
        return <TextWidget type={type} {...data}>{data.value ?? "-"}</TextWidget>;
    case "table":
        return <TableWidget {...data} />;
    case "icon":
        return <IconWidget {...data} />;
    default:
        return <TextWidget type="p" {...data}>{data?.value ?? "-"}</TextWidget>;
    }
}

export default Widget;

const Wrapper = styled.div`
    padding: 20px;
    margin-top: 20px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    border-radius: 5px;
    border-top: 4px solid ${props => props.color == "none" ? "#2684ff" : props.color};
    overflow: hidden;
    margin-bottom: ${props => props.marginBottom};

    > h4 {
        margin-bottom: 20px;
    }
`;

const WidgetInnerWrapper = styled.div`
margin-bottom: ${props => props.marginBottom};
text-align: ${props => props.align};
`;