import React, { useCallback, useState } from "react";
import Spinner from "../ui/spinner";
import DynamicTable from "@atlaskit/dynamic-table";
import styled from "styled-components";
import { Link } from "react-router-dom";
import slugify from "slugify";
import CellValue from "../../utils/cell-value";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import Lozenge from "@atlaskit/lozenge";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/base.css";
import "@inovua/reactdatagrid-enterprise/theme/default-light.css";
import DisplayDocumentByType from "./display-document-by-type";
import { useTranslation } from "react-i18next";
import toBoolean from "../../utils/to-boolean";
import InlineEdit from "../forms/inline-edit";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import Button from "@atlaskit/button";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import DocumentTableNewRecord from "./document-table-new-record";
import DocumentTableEditRecord from "./document-table-edit-record";
import DocumentTableRemoveRecord from "./document-table-remove-record";
import DocumentTableRemoveComponentRecord from "./document-table-remove-component-record";
import useGlobalConfig from "../../hooks/use-global-config";
import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";
import AddIcon from "@atlaskit/icon/glyph/add";
import Avatar from "@atlaskit/avatar";
import AvatarGroup from "@atlaskit/avatar-group";

function DocumentsList({
    isPending, fields, documents, space, columns, availableDocumentTypes = null,
    savedColumns = null, onLimitChange, onSkipChange, limit, skip,
    sortHandle, sortColumn, sortDir,
    columnVisible, onColumnVisibleChange,
    columnsOrder, setColumnsOrder,
    showColumnMenuTool = true,
    editMode = false, actionsColumn = false,
    document_links = [], reload,
    contextDocument = null, footerRows = null, summaryReducer = null,
    height = 700, linkTypeId = 3, columnsWidths = {},
    defaultColumnWidth = 200, colorField,
    addFormOpen, setAddFormOpen, createButton = false,
    columnsFlex = {},
}) {
    const data = documents?.data || [];
    const { t } = useTranslation();
    const [recordToEdit, setRecordToEdit] = useState(null);
    const { config: typesMapConfig } = useGlobalConfig("types_map");
    const [recordToRemove, setRecordToRemove] = useState(null);
    const nameField = fields?.find(i => i.name === "name") || null;
    const filteredFields = fields?.filter(i => {
        const isSection = i?.field?.type == "section";
        const isWidgets = i?.field?.type == "widgets";
        const isHidden = toBoolean(i?.hidden);
        return i.name !== "name" && !isSection && !isHidden && !isWidgets;
    });
    const quickFormFields = fields?.filter(i => toBoolean(i?.quick_form)) ?? [];

    let gridColumns = [
        { name: "name", header: nameField?.label || "Name", headerAlign: "center", visible: columnVisible?.includes("name"), showColumnMenuFilterOptions: false, defaultWidth: columnsWidths["name"], defaultFlex: columnsFlex["name"] },
        ...(filteredFields?.map(field => ({
            name: field.name,
            header: columns?.find(i => i?.document_type_field_id === field?.id)?.label ?? field.label,
            textAlign: textAlign(columns, field),
            headerAlign: "center",
            visible: columnVisible?.includes(field.name),
            sortable: ![
                "list",
                "section", "list", "multiselect", "table",
                "textarea", "wysywig"
            ].includes(field?.field?.type),
            defaultWidth: columnsWidths[field.name],
            defaultFlex: columnsFlex[field?.name],
        })) || []),
        ...(availableDocumentTypes?.map(dt => ({
            id: `document.${dt.value}`,
            name: dt.documentTypeName,
            header: dt.label,
            sortable: true,
            type: "document-type",
            headerAlign: "center",
            visible: columnVisible?.includes(dt.documentTypeName),
            defaultWidth: columnsWidths[dt.documentTypeName],
            defaultFlex: columnsFlex[dt.documentTypeName],
        })) || []),
        { name: "users", header: "Użytkownicy", headerAlign: "center", width: 100, visible: columnVisible?.includes("users"), sortable: false, defaultWidth: columnsWidths["sortable"], },
        { name: "uuid", header: "Uuid", headerAlign: "center", width: 250, visible: columnVisible?.includes("uuid"), sortable: false, defaultWidth: columnsWidths["uuid"], },
    ];

    const actionsHeader = createButton ? <Button appearance="primary" iconBefore={<AddIcon />} shouldFitContainer onClick={() => setAddFormOpen(true)} size="small" /> : "";

    if (actionsColumn) {
        gridColumns = [
            ...gridColumns,
            { name: "actions", header: actionsHeader, width: 100, sortable: false, defaultWidth: 100, visible: actionsColumn, locked: "end", showInContextMenu: false }
        ];
    }

    const RowActions = ({ document: d }) => {
        return <RowActionsWrapper>
            <Button iconBefore={<EditFilledIcon label="Star icon" size="small" />} appearance="subtle" onClick={() => {
                setRecordToEdit(d);
            }} />
            <Button iconBefore={<TrashIcon label="Star icon" size="small" />} appearance="subtle" onClick={() => {
                setRecordToRemove(d);
            }} />
        </RowActionsWrapper>;
    };

    const gridData = data.map(document => ({
        id: document.id,
        key: document.id,
        name: <RowHeader document={document} space={space} colorField={colorField} />,
        ...(filteredFields?.reduce((acc, field) => {
            const value = document.values[field.name];
            const inputProps = {
                key: `${field.id}_${document.id}`,
                name: field.name,
                isRequired: (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
                defaultValue: field?.field?.type === "checkbox" ? toBoolean(document.values[field.id]) : document.values[field.id],
                isInvalid: false,
                table_column: field?.table_column || [],
                columns_values: document?.columns_values,
                isDisabled: toBoolean(field?.read_only ?? 0),
                rawValues: document?.rawValues || [],
                autoFocus: false
            };
            acc[field.name] = <InlineEdit key={`${document.id}-${field.id}`} value={value} document={document} field={field} inputProps={inputProps} editable={editMode} />;
            return acc;
        }, {})),
        ...(availableDocumentTypes?.reduce((acc, dt) => {
            acc[dt.documentTypeName] = findRelationName(dt.value, document);
            return acc;
        }, {}) || []),
        users: <div>
            <AvatarGroup appearance="stack" maxCount={3} size="small" data={document?.users?.map(user => {
                return {
                    email: user?.user?.email,
                    key: user?.id,
                    name: `${user?.user?.first_name} ${user?.user?.last_name}`,
                    href: "#",
                    src: user?.user?.avatar_url,
                };
            })} />
        </div>,
        uuid: <small>{document?.uuid ?? "-"}</small>,
        ...(actionsColumn ? { actions: <RowActions document={document} /> } : {}),
    }));

    const gridStyle = { minHeight: height };

    const dataSource = useCallback(() => {
        return Promise.resolve({
            data: gridData,
            count: parseInt(documents?.pagination?.total) ?? 0
        });
    }, [gridData, documents?.pagination?.total]);

    const i18n = {};

    for (const [key, value] of Object.entries(ReactDataGrid.defaultProps.i18n)) {
        i18n[key] = t(`grid_${key}`);
    }

    let columnsOrderWithActions = columnsOrder;
    if (actionsColumn && columnsOrder && columnsOrderWithActions && !columnsOrderWithActions?.includes("actions")) {
        columnsOrderWithActions = [
            ...columnsOrderWithActions,
            "actions"
        ];
    }

    const rowStyle = ({ data: { id } }) => {
        const row = data?.find(i => i?.id == id);

        return {
            color: colorField && row ? row?.values[colorField] : "black",
        };
    };

    return <Wrapper>
        <ReactDataGrid
            idProperty="id"
            licenseKey="AppName=ERPSPACEApp,Company=ERPSPACE,ExpiryDate=2022-07-05,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ERPSPACELicenseRef,Z=-832501701-1692102658931955950-2095626321-13707530521688933174"
            i18n={i18n}
            style={gridStyle}
            columns={gridColumns}
            activateRowOnFocus={false}
            allowUnsort={false}
            enableSelection={false}
            rowHeight={45}
            limit={limit}
            skip={skip}
            loading={isPending}
            pagination="remote"
            rowStyle={rowStyle}
            onSkipChange={s => {
                onSkipChange(s);
            }}
            onLimitChange={l => {
                onLimitChange(l);
            }}
            showColumnMenuGroupOptions={false}
            showColumnMenuLockOptions={false}
            onSortInfoChange={sortHandle}
            sortInfo={{ name: sortColumn, dir: sortDir }}
            onColumnVisibleChange={onColumnVisibleChange}
            columnOrder={columnsOrderWithActions}
            onColumnOrderChange={setColumnsOrder}
            summaryReducer={summaryReducer}
            footerRows={footerRows}
            dataSource={dataSource} />

        {editMode && recordToEdit && <DocumentTableEditRecord
            open={true}
            onSubmit={formData => {
                console.log(formData);
                setRecordToEdit(null);
            }}
            onUpdated={() => reload()}
            documentTypeId={space?.document_type?.id}
            editable={editMode}
            fields={quickFormFields}
            record={recordToEdit}
            columns={columns}
            root={contextDocument}
            document_links={document_links}
            linkTypeId={linkTypeId}
            onClose={() => {
                setRecordToEdit(null);
                reload && reload();
            }} />}

        {editMode && addFormOpen && <DocumentTableNewRecord
            open={addFormOpen}
            onSubmit={formData => {
                const item = {
                    ...formData,
                    id: uuidv4(),
                    added: true,
                };

                console.log(item);
            }}
            editable={editMode}
            fields={quickFormFields}
            columns={columns}
            document_links={document_links}
            document={contextDocument}
            documentTypeId={space?.document_type?.id}
            linkTypeId={linkTypeId}
            onClose={() => {
                setAddFormOpen(false);
                reload && reload();
            }} />}
        {editMode && recordToRemove && typesMapConfig?.componentId !== linkTypeId && <DocumentTableRemoveRecord
            document={contextDocument}
            linkId={recordToRemove?.id}
            onRemoved={() => {
                setRecordToRemove(false);
                reload && reload();
            }}
            onClose={() => setRecordToRemove(false)} />
        }
        {editMode && recordToRemove && typesMapConfig?.componentId == linkTypeId && <DocumentTableRemoveComponentRecord
            document={contextDocument}
            linkId={recordToRemove?.id}
            onRemoved={() => {
                setRecordToRemove(false);
                reload && reload();
            }}
            onClose={() => setRecordToRemove(false)} />
        }
    </Wrapper>;
}

function RowHeader({ document, space, colorField }) {
    const color = colorField ? document?.values[colorField] : "0052CC";
    return <RowLinkWrapperOuter color={color}>
        <RowLink document={document} space={space}>
            <DocumentLinkRow>
                <DocumentLinkIcon>
                    <Page16Icon />
                </DocumentLinkIcon>
                {document.name}
            </DocumentLinkRow>
        </RowLink>
    </RowLinkWrapperOuter>;
}

const RowLinkWrapperOuter = styled.div`
    a {
        color: ${props => props.color};
    }
`;

function RowLink({ document, space, children }) {
    const path = `${space?.category?.module?.slug}/${space.category?.slug}/${space.slug}`.toLowerCase();
    return <Link to={`/${path}/${document.id}`}>
        <RowLinkWrapper>{children}</RowLinkWrapper>
    </Link>;
}

export default DocumentsList;

const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
`;

const DocumentLinkRow = styled.div`
    display: flex;
    font-weight: 600;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
    margin-right: 5px;
`;

const RowLinkWrapper = styled.div`
    font-weight: bold;
`;

const RowSpacer = styled.div`
    height: 30px;value?.dir
    display: flex;
    align-items: center;
    justify-content: center;
`;

function findRelationName(documentTypeId, document) {
    const links = document?.links?.filter(i => i?.document_type?.id === documentTypeId);
    if (links?.length === 0) return "-";
    return links?.map(link => (
        <DocumentLinkWrapper key={link?.id}>
            <DisplayDocumentByType
                key={link?.id}
                id={link?.id}
                documentTypeId={documentTypeId} />
        </DocumentLinkWrapper>
    ));
}

const DocumentLinkWrapper = styled.div`
    display: inline-block;
`;

const RowActionsWrapper = styled.div`
    display: flex;
`;

function textAlign(columns, field) {
    const align = columns?.find(i => i?.document_type_field_id === field?.id)?.align;

    if (align === "right") return "end";
    if (align === "left") return "start";
    if (align === "center") return "center";

    return "center";
}

const UsersWrapper = styled.div`
    position: relative;
`;
