import { useCallback, useEffect } from "react";
import apiClient from "../api/business-client";
import useAsync from "./use-async";

export default function useOrder(orderId) {
    const { data, isIdle, isPending, isError, isSuccess, run } = useAsync();

    useEffect(() => {
        if (!orderId) return;
        reload();
    }, [orderId]);

    const reload = useCallback(() => run(apiClient(`orders/${orderId}`)).catch(() => { }));
    const pay = useCallback(data => run(apiClient("payments", { method: "POST", data: data })));

    return {
        order: data,
        isPending: isPending,
        isError,
        isSuccess,
        reload,
        pay,
    };
}