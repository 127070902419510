import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth-provider";
import styled from "styled-components";
import {
    AtlassianNavigation, ProductHome, Search,
} from "@atlaskit/atlassian-navigation";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { NotificationsPopup } from "../notifications/notifications-popup";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/LogoErp.svg";
import Avatar, { AvatarItem } from "@atlaskit/avatar";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import AppSwitcher from "./app-switcher";
import toBoolean from "../../utils/to-boolean";
import { useTranslation } from "react-i18next";

function Navbar({ user, logout }) {
    return <Wrapper>
        <AtlassianNavigation
            renderProductHome={Home}
            renderProfile={() => <UserProfile user={user} logout={logout} />}
            renderCreate={CreateButton}
            renderNotifications={NotificationsPopup}
            primaryItems={[
            ]}
            renderAppSwitcher={AppSwitcher}
        />
    </Wrapper>;
}

function Home() {
    return <LogoWrapper>
        <Link to="/">
            <Logo />
        </Link>
    </LogoWrapper>;
}

function UserProfile({ user, logout }) {
    const { t } = useTranslation();
    if (!user) return null;

    const onLogout = () => {
        logout();
    };

    return <>
        <DropdownMenu position="bottom right" trigger={
            <AvatarWrapper>
                <AvatarItem
                    avatar={<Avatar src={user?.avatar_url} size="small" />}
                />
            </AvatarWrapper>
        }>
            <DropdownItemGroup>
                <Link to="/profile">
                    <DropdownItem id="email">
                        {t("nav_settings")}
                    </DropdownItem>
                </Link>
                {toBoolean(user?.is_admin) && <Link to="/admin">
                    <DropdownItem id="admin">
                        {t("nav_admin")}
                    </DropdownItem>
                </Link>}
                <DropdownItem id="logout" onClick={() => onLogout()}>
                    {t("nav_logout")}
                </DropdownItem>
            </DropdownItemGroup>
        </DropdownMenu>
    </>;
}

function CreateButton() {
    return null;
}

function SearchInput() {
    return <Search placeholder="Szukaj..." />;
}

export default Navbar;

const Wrapper = styled.div`
        position: fixed;
        width: 100%;
        height: 56px;
        top: 0;
        left: 0;
        z-index: 203;
    `;

const AvatarImg = styled.img`
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-top: 5px;
`;

const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AvatarIconWrapper = styled.div`
    cursor: pointer;
    padding-top: 5px;
`;

const LogoWrapper = styled.div`
    margin: 10px 5px;
`;

