import Spinner from "../ui/spinner";
import React, { useState } from "react";
import "react-pivottable/pivottable.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import useDocumentsPivotList from "../../hooks/use-documents-pivot-list";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";

const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function DocumentsListPivot({ id, fieldNames = [], options = {
    numericFieldTypeId: 2, 
    dateFieldTypeId: 4, 
    selectFieldTypeId: 3, 
} }) {
    const { documents, isPending } = useDocumentsPivotList(id, fieldNames, options);
    const [ state, setState ] = useState(null);

    if (isPending) {
        return <Spinner />;
    }

    const data = documents || [];

    return <PivotTableUI 
        data={data} 
        unusedOrientationCutoff={Infinity}
        {...state} 
        onChange={e => {
            setState(e);
        }}
        dataSourceSettings={{
            
        }}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} />;
}